import React, { useEffect, useState } from "react";
// import "./global.scss";
// import "./index.scss";
import "../styles/3.scss";
import FormInput from "./common/FormInput";
import SelectBox from "./common/SelectBox";
import { ReactComponent as SelectedCircle } from "../assets/icon/selectedCircle.svg";
import { ReactComponent as CompletededCircle } from "../assets/icon/completedCircle.svg";
import { ReactComponent as NotSelectdCircle } from "../assets/icon/unselectCircle.svg";
import { ReactComponent as SelectedBar } from "../assets/icon/selectedBar.svg";
import { ReactComponent as UnSelectedBar } from "../assets/icon/unselectBar.svg";
import {
  citiesOfOdisha,
  countries,
  districtsOfOdisha,
  statesOfIndia,
} from "../data/selectData";
import MenuIcon from "../assets/icon/menu-fill@1x.svg";
import DashboardIcon from "../assets/icon/dashboard-fill@1x.svg";
import PieChartIcon from "../assets/icon/pie-chart-2-fill@1x.svg";
import HeartIcon from "../assets/icon/heart-3-fill@1x.svg";
import MailIcon from "../assets/icon/mail-fill@1x.svg";
import CameraIcon from "../assets/icon/camera@1x.svg";
import MoreIcon from "../assets/icon/more-Icon@1x.svg";

const Address = ({
  values,
  onSubmit,
  setTab,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  errors,
  completedTab,
  selectedTab
}) => {
  const [selectTab, setselectTab] = useState(1);
  React.useEffect(() => {
    // Initialize the code
    return () => {};
  }, []);
 
  const handleTab=(v)=>{
    if(completedTab?.includes(v)&& Object.keys(errors)?.length===0){
      setTab(v)
    }
  }
  useEffect(()=>{
    if(selectedTab===3){

      onSubmit()
    }
  },[selectedTab])
  return (
    <div className={"android-large-3"}>
      <div className={"frame-2"}>
      <div className={"navbar"}>
          <div className={"menu-fill"}>
            <img src={MenuIcon} />
          </div>
          <div className={"dashboard-fill"}>
            <img src={DashboardIcon} />
          </div>
          <div className={"pie-chart-2-fill"}>
            <img src={PieChartIcon} />
          </div>
          <div className={"heart-3-fill"}>
            <img src={HeartIcon} />
          </div>
          <div className={"mail-fill"}>
            <img src={MailIcon} />
          </div>
          <div className={"more-fill"}>
            <img src={MoreIcon} />
          </div>
        </div>
        <div className={"navbar-1"}>
          <div className={"nav"}>
            <div className={"registration"}>Registration</div>
            <div className={"location"}>
              <svg id="7:91026" className={"icon-1"}></svg>
              <div className={"text-102038161"}>10.20.38.161</div>
            </div>
          </div>
          <div className={"search"}>
            <div className={"placeholder"}>
            <div className={"camera-fill"}>
                <img src={CameraIcon} />
              </div>
              <div className={"divider"}></div>
              <div className={"search-for-document"}>Search for document</div>
            </div>
            <div className={"button"}>
              <div className={"search-1"}>Search</div>
            </div>
          </div>
        </div>
        <div className={"steps"}>
          <div onClick={()=>handleTab(1)} className={"step"}>
            <div  className={"basic"}>Basic</div>

            {
              completedTab.includes(1)?
              <CompletededCircle/>:
            <div className={"number"}></div>
              }
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(1)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(2)} className={"step-1"}>
            <div  className={"referral"}>Referral</div>
            {
              completedTab.includes(2)?
              <CompletededCircle/>:
              <div className={"number"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(2)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(3)} className={"step-2"}>
            <div className={"address"}>Address</div>
            {
              Object.keys(errors).length === 0?
              <CompletededCircle/>:
              <SelectedCircle/>}
          </div>
          <div className=" h-[29%] ">
          {
              Object.keys(errors).length === 0&&completedTab.includes(3)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(4)} className={"step-3"}>
            <div className={"contact"}>Contact</div>
            {
              completedTab.includes(4)?
              <CompletededCircle/>:
            <div className={"number-1"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(4)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(5)} className={"step-4"}>
            <div className={"payment"}>Payment</div>
            {
              completedTab.includes(5)?
              <CompletededCircle/>:
            <div className={"number-2"}></div>}
          </div>
        </div>
        <div className={"tabs"}>
          <div
            onClick={() => setselectTab(1)}
            className={selectTab === 1 ? "itemchecked" : "itemnormal"}
          >
            <div className={selectTab === 1 ? "present" : "permanent"}>
              Present
            </div>
            {selectTab === 1 && <div className={"checked"}></div>}
          </div>
          <div
            onClick={() => setselectTab(2)}
            className={selectTab === 2 ? "itemchecked" : "itemnormal"}
          >
            <div className={selectTab === 2 ? "present" : "permanent"}>
              Permanent
            </div>
            {selectTab === 2 && <div className={"checked"}></div>}
          </div>
          {/* <div className={'itemnormal-1'}>
                <div className={'others'}>
                    Others
                </div>
            </div>
            <svg id="7:91116" className={'frame-3441'}></svg> */}
        </div>
        {selectTab === 1 && (
          <div className={"form"}>
            <div className={"frame-3439"}>
              <div className={"form-1"}>
                <div
                  style={
                    errors.presentAddress &&
                    touched.presentAddress && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem"}
                >
                  <div className={"name"}>
                    <div className={"text after:content-['*'] after:ml-0.5 after:text-red-500"}>Address 1</div>
                    <FormInput
                      value={values.presentAddress}
                      name={"presentAddress"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.presentAddress1 &&
                    touched.presentAddress1 && {
                      borderBottom: " 1px solid red",
                    }
                  }
                  className={"formitem-1"}
                >
                  <div className={"name-1"}>
                    <div className={"text-1"}>Address 2</div>
                    <FormInput
                      value={values.presentAddress2}
                      name={"presentAddress2"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter-1"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.presentArea &&
                    touched.presentArea && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-2"}
                >
                  <div className={"name-2"}>
                    <div className={"text-2 after:content-['*'] after:ml-0.5 after:text-red-500"}>Area</div>
                    <FormInput
                      value={values.presentArea}
                      name={"presentArea"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter-2"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.presentZip &&
                    touched.presentZip && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-3"}
                >
                  <div className={"name-3"}>
                    <div className={"text-3"}>Pin/Zip</div>
                    <FormInput
                      type={"number"}
                      maxLength={6}
                      value={values.presentZip}
                      name={"presentZip"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter-3"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.presentCity &&
                    touched.presentCity && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-4"}
                >
                  <div className={"select"}>City</div>
                  <SelectBox
                    value={values.presentCity}
                    setFieldValue={setFieldValue}
                    name={"presentCity"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={citiesOfOdisha}
                    isBorderPadding={false}
                    customClass={"select-1"}
                  />
                </div>
                <div
                  style={
                    errors.presentDistrict &&
                    touched.presentDistrict && {
                      borderBottom: " 1px solid red",
                    }
                  }
                  className={"formitem-5"}
                >
                  <div className={"select-2"}>District</div>
                  <SelectBox
                    value={values.presentDistrict}
                    name={"presentDistrict"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={districtsOfOdisha}
                    isBorderPadding={false}
                    setFieldValue={setFieldValue}
                    customClass={"select-3"}
                  />
                </div>
                <div
                  style={
                    errors.presentState &&
                    touched.presentState && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-6"}
                >
                  <div className={"select-4"}>State</div>
                  <SelectBox
                    value={values.presentState}
                    setFieldValue={setFieldValue}
                    name={"presentState"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={statesOfIndia}
                    isBorderPadding={false}
                    customClass={"select-5"}
                  />
                </div>
                <div
                  style={
                    errors.presentCounty &&
                    touched.presentCounty && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-7"}
                >
                  <div className={"select-6 after:content-['*'] after:ml-0.5 after:text-red-500"}>Country</div>
                  <SelectBox
                    value={values.presentCounty}
                    setFieldValue={setFieldValue}
                    name={"presentCounty"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={countries}
                    isBorderPadding={false}
                    customClass={"select-7"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {selectTab === 2 && (
          <div className={"form"}>
            <div className={"frame-3439"}>
              <div className={"form-1"}>
                <div
                  style={
                    errors.permanentAddress &&
                    touched.permanentAddress && {
                      borderBottom: " 1px solid red",
                    }
                  }
                  className={"formitem"}
                >
                  <div className={"name"}>
                    <div className={"text after:content-['*'] after:ml-0.5 after:text-red-500"}>Address 1</div>
                    <FormInput
                      value={values.permanentAddress}
                      name={"permanentAddress"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter"}
                    />
                  </div>
                </div>
                <div className={"formitem-1"}>
                  <div className={"name-1"}>
                    <div className={"text-1"}>Address 2</div>
                    <FormInput
                      style={
                        errors.permanentAddress2 &&
                        touched.permanentAddress2 && {
                          borderBottom: " 1px solid red",
                        }
                      }
                      value={values.permanentAddress2}
                      name={"permanentAddress2"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter-1"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.permanentArea &&
                    touched.permanentArea && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-2"}
                >
                  <div className={"name-2"}>
                    <div className={"text-2 after:content-['*'] after:ml-0.5 after:text-red-500"}>Area</div>
                    <FormInput
                      value={values.permanentArea}
                      name={"permanentArea"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter-2"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.permanentZip &&
                    touched.permanentZip && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-3"}
                >
                  <div className={"name-3"}>
                    <div className={"text-3"}>Pin/Zip</div>
                    <FormInput
                      value={values.permanentZip}
                      name={"permanentZip"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isBorderPadding={true}
                      customClass={"enter-3"}
                    />
                  </div>
                </div>
                <div
                  style={
                    errors.permanentCity &&
                    touched.permanentCity && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-4"}
                >
                  <div className={"select"}>City</div>
                  <SelectBox
                    value={values.permanentCity}
                    setFieldValue={setFieldValue}
                    name={"permanentCity"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={citiesOfOdisha}
                    isBorderPadding={false}
                    customClass={"select-1"}
                  />
                </div>
                <div
                  style={
                    errors.permanentDistrict &&
                    touched.permanentDistrict && {
                      borderBottom: " 1px solid red",
                    }
                  }
                  className={"formitem-5"}
                >
                  <div className={"select-2"}>District</div>
                  <SelectBox
                    value={values.permanentDistrict}
                    setFieldValue={setFieldValue}
                    name={"permanentDistrict"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={districtsOfOdisha}
                    isBorderPadding={false}
                    customClass={"select-3"}
                  />
                </div>
                <div
                  style={
                    errors.permanentState &&
                    touched.permanentState && { borderBottom: " 1px solid red" }
                  }
                  className={"formitem-6"}
                >
                  <div className={"select-4"}>State</div>
                  <SelectBox
                    value={values.permanentState}
                    setFieldValue={setFieldValue}
                    name={"permanentState"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={statesOfIndia}
                    isBorderPadding={false}
                    customClass={"select-5"}
                  />
                </div>
                <div
                  style={
                    errors.permanentCounty &&
                    touched.permanentCounty && {
                      borderBottom: " 1px solid red",
                    }
                  }
                  className={"formitem-7"}
                >
                  <div className={"select-6 after:content-['*'] after:ml-0.5 after:text-red-500"}>Country</div>
                  <SelectBox
                    value={values.permanentCounty}
                    setFieldValue={setFieldValue}
                    name={"permanentCounty"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={countries}
                    isBorderPadding={false}
                    customClass={"select-7"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={"buttons"}>
        <div onClick={() => setTab(2)} className={"button-1"}>
          <div className={"back"}>Back</div>
        </div>
        <div onClick={onSubmit} className={"button-2"}>
          <div className={"next"}>Next</div>
        </div>
      </div>
    </div>
  );
};
export default Address;
