export const WebViewInput = ({
  label,
  parentClassName,
  labelClassName,
  className,
  isImportant,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <>
      {/* <label className={parentClassName}> */}
        {/* <div className={labelClassName}> */}
      <label className="formitem">
        <div className="formitem-label">
          <span>{label} </span>
          {isImportant && <span>*</span>}
        </div>
        <input
        autoComplete="new-one"
          // className={className}
          className="form-input"
          style={error ? { border: "1px solid red" } : {}}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </label>
    </>
  );
};
export const WebViewDateInput = ({
  label,
  parentClassName,
  labelClassName,
  className,
  isImportant,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <>
      {/* <label className={parentClassName}>
        <div className={labelClassName}> */}
      <label className="formitem">
        <div className="formitem-label">
          <span>{label} </span>
          {isImportant && <span>*</span>}
        </div>
        <input
          // className={className}
          className="form-input w-full"
          style={error ? { border: "1px solid red" } : {}}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          type="date"
          placeholder={placeholder}
        />
      </label>
    </>
  );
};

export const WebViewSelect = ({
  label,
  options,
  parentClassName,
  labelClassName,
  className,
  name,
  value,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <>
      {/* <label className={parentClassName}>
        <div className={labelClassName}> */}
        <label className="formitem">
        <div className="formitem-label">
          {label}
        </div>
        <select
          // className={className}
          className="form-input"
          style={error ? { border: "1px solid red" } : {}}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        >
          {options?.map((item, i) => (
            <option value={item.value} key={i}>
              {item?.label}
            </option>
          ))}
        </select>
      </label>
    </>
  );
};
