import React, { useEffect } from "react";
import SelectBox from "./common/SelectBox";
import FormInput from "./common/FormInput";
import DateField from "./common/DateField";
import { ReactComponent as SelectedCircle } from "../assets/icon/selectedCircle.svg";
import { ReactComponent as CompletededCircle } from "../assets/icon/completedCircle.svg";
import { ReactComponent as NotSelectdCircle } from "../assets/icon/unselectCircle.svg";
import { ReactComponent as SelectedBar } from "../assets/icon/selectedBar.svg";
import { ReactComponent as UnSelectedBar } from "../assets/icon/unselectBar.svg";

import { date } from "yup";
import {
  idProofOptions,
  nationalityOptions,
  occupationOptions,
  patientCategoryOptions,
  raceOptions,
  regTypeOptions,
  religionOptions,
  umrOptions,
  visitedTypeOptions,
} from "../data/selectData";
// import "./global.scss";
// import "./index.scss";
import MenuIcon from "../assets/icon/menu-fill@1x.svg";
import DashboardIcon from "../assets/icon/dashboard-fill@1x.svg";
import PieChartIcon from "../assets/icon/pie-chart-2-fill@1x.svg";
import HeartIcon from "../assets/icon/heart-3-fill@1x.svg";
import MailIcon from "../assets/icon/mail-fill@1x.svg";
import CameraIcon from "../assets/icon/camera@1x.svg";
import MoreIcon from "../assets/icon/more-Icon@1x.svg";

const Root = ({
  values,
  onSubmit,
  setTab,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  errors,
  completedTab,
  handleCompleteTab
}) => {
 
  React.useEffect(() => {
    // Initialize the code
    return () => {};
  }, []);

  const handleRegularVipVvipTab = (val) => {
    setFieldValue("regularVipVvip", val);
  };

  const handlePatientType = (val) => {
    setFieldValue("patientType", val);
  };

  const handleTab=(v)=>{
    if(completedTab?.includes(v)&&Object.keys(errors).length===0){
      setTab(v)
    }
  }
  useEffect(()=>{
    onSubmit()
  },[])
 
  return (
    <div className={"android-large-1"} style={{ width: "100%" }}>
      <div className={"frame-2"}>
        <div className={"navbar"}>
          <div className={"menu-fill"}>
            <img src={MenuIcon} />
          </div>
          <div className={"dashboard-fill"}>
            <img src={DashboardIcon} />
          </div>
          <div className={"pie-chart-2-fill"}>
            <img src={PieChartIcon} />
          </div>
          <div className={"heart-3-fill"}>
            <img src={HeartIcon} />
          </div>
          <div className={"mail-fill"}>
            <img src={MailIcon} />
          </div>
          <div className={"more-fill"}>
            <img src={MoreIcon} />
          </div>
        </div>
        <div className={"navbar-1"}>
          <div className={"nav"}>
            <div className={"registration"}>Registration</div>
            <div className={"location"}>
              <svg id="7:84098" className={"icon-1"}></svg>
              <div className={"text-102038161"}>10.20.38.161</div>
            </div>
          </div>
          <div className={"search"}>
            <div className={"placeholder"}>
              <div className={"camera-fill"}>
                <img src={CameraIcon} />
              </div>
              <div className={"divider"}></div>
              <div className={"search-for-document"}>Search for document</div>
            </div>
            <div className={"button"}>
              <div className={"search-1"}>Search</div>
            </div>
          </div>
        </div>
        <div className={"steps"}>
          <div className={"step"}>
            <div className={"basic"}>Basic</div>
            {
              Object.keys(errors).length === 0?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] ">
          {
              Object.keys(errors).length === 0&&completedTab.includes(1)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>

          <div onClick={()=>handleTab(2)} className={"step-1"}>
            <div className={"referral"}>Referral</div>
            {
              completedTab.includes(2)?
              <CompletededCircle/>:
            <div className={"number-1"}></div>
            }
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(2)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(3)} className={"step-2"}>
            <div  className={"address"}>Address</div>
            {
              completedTab.includes(3)?
              <CompletededCircle/>:
            <div className={"number-2"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(3)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(4)} className={"step-3"}>
            <div className={"contact"}>Contact</div>
            {
              completedTab.includes(4)?
              <CompletededCircle/>:
            <div className={"number-3"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(4)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div className={"step-4"}>
            <div className={"payment"}>Payment</div>
            {
              completedTab.includes(5)?
              <CompletededCircle/>:
            <div className={"number-4"}></div>}
          </div>
        </div>
        <div className={"form"}>
          <div className={"frame-3447"}>
            <div className={"patient-type"}>Patient Type</div>
            <div className={"tags"}>
              <div className={"row1"}>
                <div
                  onClick={() => handlePatientType("senior citizen")}
                  className={
                    values.patientType === "senior citizen" ? "tag-select" : "tag"
                  }
                >
                  <div
                    className={
                      values.patientType === "senior citizen"
                        ? "senior-citizen-select"
                        : "senior-citizen"
                    }
                  >
                    Senior Citizen
                  </div>
                </div>
                <div
                  onClick={() => handlePatientType("dnd")}
                  className={values.patientType === "dnd" ? "tag-1" : "tag-2"}
                >
                  <div className={values.patientType === "dnd" ? "dnd" : "mlc"}>
                    DND
                  </div>
                </div>
                <div
                  onClick={() => handlePatientType("mlc")}
                  className={values.patientType === "mlc" ? "tag-1" : "tag-2"}
                >
                  <div className={values.patientType === "mlc" ? "dnd" : "mlc"}>
                    MLC
                  </div>
                </div>
              </div>
              <div className={"row2"}>
                <div  onClick={() => handlePatientType("health card")}
                  className={values.patientType === "health card" ? "tag-3-select" : "tag-3"}>
                  <div className={values.patientType === "health card"?"health-card-select":"health-card"}>Health Card</div>
                </div>
                <div onClick={() => handlePatientType("new born")}
                  className={values.patientType === "new born" ? "tag-4-select" : "tag-4"}>
                  <div className={values.patientType === "new born" ?"new-born-select":"new-born"}>New Born</div>
                </div>
                <div onClick={() => handlePatientType("renewal")}
                  className={values.patientType === "renewal" ? "tag-5-select" : "tag-5"}>
                  <div className={values.patientType === "renewal" ?"renewal-select":"renewal"}>Renewal</div>
                </div>
              </div>
            </div>
          </div>
          <div className={"frame-3446"}>
            <div className={"patient-details"}>Patient Details</div>
            <div className={"form-1"}>
              <div
                style={
                  errors.title &&
                  touched.title && { borderBottom: " 1px solid red" }
                }
                className={"formitem"}
              >
                <div className={"select after:content-['*'] after:ml-0.5 after:text-red-500"}>Title</div>
                <SelectBox
                  value={values.title}
                  name={"title"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: "mr", label: "Mr" },
                    { value: "mrs", label: "Mrs" },
                  ]}
                  customClass={"select-1"}
                  isBorderPadding={false}
                  isHidden={true}
                />

                {/* <svg id="7:026861/7:026685" className={'icon-3'}></svg> */}
              </div>
              <div
                style={
                  errors.firstName &&
                  touched.firstName && { borderBottom: " 1px solid red" }
                }
                className={"formitem-1"}
              >
                <div className={"name"}>
                  <div className={"text after:content-['*'] after:ml-0.5 after:text-red-500"}>First Name</div>
                  
                  <FormInput
                    value={values.firstName}
                    name={"firstName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.middleName &&
                  touched.middleName && { borderBottom: " 1px solid red" }
                }
                className={"formitem-2"}
              >
                <div className={"name-1"}>
                  <div className={"text-1"}>Middle Name</div>

                  <FormInput
                    value={values.middleName}
                    name={"middleName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-1"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.lastName &&
                  touched.lastName && { borderBottom: " 1px solid red" }
                }
                className={"formitem-3"}
              >
                <div className={"name-2"}>
                  <div className={"text-2"}>Last Name</div>
                  <FormInput
                    value={values.lastName}
                    name={"lastName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-2"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.gender &&
                  touched.gender && { borderBottom: " 1px solid red" }
                }
                className={"formitem-4"}
              >
                <div className={"select-2"}>Gender</div>
                <SelectBox
                  value={values.gender}
                  name={"gender"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                  customClass={"select-3"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.date &&
                  touched.date && { borderBottom: " 1px solid red" }
                }
                className={"formitem-5"}
              >
                <div className={"select-4"}>Birthday</div>
                <DateField
                  value={values.date}
                  name={"date"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  customClass={"select-5"}
                />
              </div>

              <div
                style={
                  errors.patCategory &&
                  touched.patCategory && { borderBottom: " 1px solid red" }
                }
                className={"formitem-7"}
              >
                <div className={"select-8"}>Pat Category</div>
                <SelectBox
                  value={values.patCategory}
                  name={"patCategory"}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  options={patientCategoryOptions}
                  customClass={"select-9"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.bloodGroup &&
                  touched.bloodGroup && { borderBottom: " 1px solid red" }
                }
                className={"formitem-8"}
              >
                <div className={"select-10"}>Blood Group</div>
                <SelectBox
                  value={values.bloodGroup}
                  setFieldValue={setFieldValue}
                  name={"bloodGroup"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: "a+", label: "A+" },
                    { value: "b+", label: "B+" },
                  ]}
                  customClass={"select-11"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
            </div>
          </div>
          <div className={"frame-3444"}>
            <div className={"more-information"}>More Information</div>
            <div className={"form-2"}>
              <div
                style={
                  errors.occupation &&
                  touched.occupation && { borderBottom: " 1px solid red" }
                }
                className={"formitem-9"}
              >
                <div className={"select-12"}>Occupation</div>
                <SelectBox
                  value={values.occupation}
                  name={"occupation"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  options={occupationOptions}
                  customClass={"select-13"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.maritalStatus &&
                  touched.maritalStatus && { borderBottom: " 1px solid red" }
                }
                className={"formitem-10"}
              >
                <div className={"select-14"}>Marital Status</div>
                <SelectBox
                  value={values.maritalStatus}
                  name={"maritalStatus"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: "married", label: "Married" },
                    { value: "unmarried", label: "Unmarried" },
                  ]}
                  customClass={"select-15"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.nationality &&
                  touched.nationality && { borderBottom: " 1px solid red" }
                }
                className={"formitem-11"}
              >
                <div className={"select-16"}>Nationality</div>
                <SelectBox
                setFieldValue={setFieldValue}
                  value={values.nationality}
                  name={"nationality"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={nationalityOptions}
                  customClass={"select-17"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.race &&
                  touched.race && { borderBottom: " 1px solid red" }
                }
                className={"formitem-12"}
              >
                <div className={"select-18"}>Race</div>
                <SelectBox
                  value={values.race}
                  name={"race"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={raceOptions}
                  customClass={"select-19"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.religion &&
                  touched.religion && { borderBottom: " 1px solid red" }
                }
                className={"formitem-13"}
              >
                <div className={"select-20"}>Religion</div>
                <SelectBox
                  value={values.religion}
                  name={"religion"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={religionOptions}
                  customClass={"select-21"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
            </div>
          </div>
          <div className={"frame-3445"}>
            <div className={"family-details"}>Family Details</div>
            <div className={"form-3"}>
              <div
                style={
                  errors.fathersName &&
                  touched.fathersName && { borderBottom: " 1px solid red" }
                }
                className={"formitem-14"}
              >
                <div className={"name-3"}>
                  <div className={"text-3"}>Father Name</div>
                  <FormInput
                    value={values.fathersName}
                    name={"fathersName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-3"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.mothersName &&
                  touched.mothersName && { borderBottom: " 1px solid red" }
                }
                className={"formitem-15"}
              >
                <div className={"name-4"}>
                  <div className={"text-4"}>Mother Name</div>
                  <FormInput
                    value={values.mothersName}
                    name={"mothersName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-4"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.kinName &&
                  touched.kinName && { borderBottom: " 1px solid red" }
                }
                className={"formitem-16"}
              >
                <div className={"name-5"}>
                  <div className={"text-5"}>Kin Name</div>
                  <FormInput
                    value={values.kinName}
                    name={"kinName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-5"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"frame-3443"}>
            <div className={"verification"}>Verification</div>
            <div className={"form-4"}>
              <div
                style={
                  errors.idProofType &&
                  touched.idProofType && { borderBottom: " 1px solid red" }
                }
                className={"formitem-17"}
              >
                <div className={"select-22"}>ID Proof</div>
                <SelectBox
                  value={values.idProofType}
                  name={"idProofType"}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  options={idProofOptions}
                  customClass={"select-23"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div
                style={
                  errors.idProof &&
                  touched.idProof && { borderBottom: " 1px solid red" }
                }
                className={"formitem-18"}
              >
                <div className={"name-6"}>
                  <div className={"text-6"}>Passport</div>
                  <FormInput
                    value={values.idProof}
                    name={"idProof"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"enter-6"}
                    isBorderPadding={true}
                  />
                </div>
              </div>
              <div
                style={
                  errors.issueDate &&
                  touched.issueDate && { borderBottom: " 1px solid red" }
                }
                className={"formitem-19"}
              >
                <div className={"name-7"}>
                  <div className={"text-7"}>Issue Date</div>
                  <DateField
                    value={values.issueDate}
                    name={"issueDate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"enter-7"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.expireDate &&
                  touched.expireDate && { borderBottom: " 1px solid red" }
                }
                className={"formitem-20"}
              >
                <div className={"name-8"}>
                  <div className={"text-8"}>Expiry Date</div>
                  <DateField
                    value={values.expireDate}
                    name={"expireDate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"enter-8"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.issuedAt &&
                  touched.issuedAt && { borderBottom: " 1px solid red" }
                }
                className={"formitem-21"}
              >
                <div className={"name-9"}>
                  <div className={"text-9"}>issued At</div>
                  <DateField
                    value={values.issuedAt}
                    name={"issuedAt"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"enter-9"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"frame-3450"}>
            <div className={"input"} >
              <div className={"doctor"}>Doctor</div>
              <FormInput
                error={errors.doctor && touched.doctor}
                value={values.doctor}
                name={"familyRef"}
                onChange={handleChange}
                onBlur={handleBlur}
                customClass={"input-1"}
              />
            </div>
            <div className={"input-2"} >
              <div className={"family-reff"}>Family Reff.</div>
              <FormInput
                error={errors.familyRef && touched.familyRef}
                value={values.familyRef}
                name={"familyRef"}
                onChange={handleChange}
                onBlur={handleBlur}
                customClass={"input-3"}
              />
            </div>
            <div className={"input-4"}>
              <div className={"how-did-you-hear-about-us"}>
                How did you hear about us?
              </div>
              <div
                style={
                  errors.howYouHear &&
                  touched.howYouHear && { border: "1px solid red" }
                }
                className="border-[1px] border-[#D9D9DE] w-full  min-h-[32px] rounded-[4px]"
              >
                <SelectBox
                  value={values.howYouHear}
                  name={"howYouHear"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: "social", label: "Social media" },
                    { value: "friend", label: "Friend" },
                    { value: "other", label: "Other" },
                  ]}
                  customClass={"select-24"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
            </div>
          </div>
          <div className={"frame-3440"}>
            <div className={"tabs"}>
              <div
                onClick={() => handleRegularVipVvipTab("regular")}
                className={
                  values?.regularVipVvip === "regular"
                    ? "itemchecked"
                    : "itemnormal"
                }
              >
                <div
                  className={
                    values?.regularVipVvip === "regular" ? "regular" : "vip"
                  }
                >
                  Regular
                </div>
              </div>
              <div
                onClick={() => handleRegularVipVvipTab("vip")}
                className={
                  values?.regularVipVvip === "vip"
                    ? "itemchecked"
                    : "itemnormal"
                }
              >
                <div
                  className={
                    values?.regularVipVvip === "vip" ? "regular" : "vip"
                  }
                >
                  VIP
                </div>
              </div>
              <div
                onClick={() => handleRegularVipVvipTab("vvip")}
                className={
                  values?.regularVipVvip === "vvip"
                    ? "itemchecked"
                    : "itemnormal"
                }
              >
                <div
                  className={
                    values?.regularVipVvip === "vvip" ? "regular" : "vip"
                  }
                >
                  VVIP
                </div>
              </div>
            </div>
            <div className={"options"}>
              <div className={"input-6"}>
                <div className={"reg-type"}>Reg Type</div>
                <div
                  style={
                    errors.regType &&
                    touched.regType && { border: "1px solid red" }
                  }
           
                  className="border-[1px] border-[#D9D9DE] w-[140px]  min-h-[32px] rounded-[4px]"
                >
                  <SelectBox
                    value={values.regType}
                    setFieldValue={setFieldValue}
                    name={"regType"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={regTypeOptions}
                     customClass={"select-25 "}
                    isBorderPadding={false}
                    isHidden={true}
                  />
                </div>
              </div>
              <div className={"input-8"}>
                <div className={"umr"}>UMR</div>
                <div
                  style={
                    errors.umr && touched.umr && { border: "1px solid red" }
                  }
                  className="border-[1px] border-[#D9D9DE] w-[140px]  min-h-[32px] rounded-[4px]"
                >
                  <SelectBox
                    value={values.umr}
                    setFieldValue={setFieldValue}
                    name={"umr"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={umrOptions}
                    customClass={"select-26"}
                    isBorderPadding={false}
                    isHidden={true}
                  />
                </div>
              </div>
              <div className={"input-10"}>
                <div className={"reg-fee"}>Reg.#/Fee</div>
                <div
                  style={
                    errors.regFee &&
                    touched.regFee && { border: "1px solid red" }
                  }
                  className={"input-11"}
                >
                  <FormInput
                    type={"number"}
                    value={values.regFee}
                    name={"regFee"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-10"}
                  />
                </div>
              </div>
              <div className={"input-12"}>
                <div className={"reg-dt"}>Reg Dt</div>
                <div
                  style={
                    errors.regDate &&
                    touched.regDate && { border: "1px solid red" }
                  }
                  className={"input-13"}
                >
                  <DateField
                    value={values.regDate}
                    name={"regDate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"select-27"}
                  />
                </div>
              </div>
              <div className={"input-14"}>
                <div className={"reg-exp-dt"}>Reg Exp Dt</div>
                <div
                  style={
                    errors.regExp &&
                    touched.regExp && { border: "1px solid red" }
                  }
                  className={"input-15"}
                >
                  <DateField
                    value={values.regExp}
                    name={"regExp"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"select-28"}
                  />
                </div>
              </div>
              <div className={"input-16"}>
                <div className={"visit-type"}>Visit Type</div>
                <div
                  style={
                    errors.visitedType &&
                    touched.visitedType && { border: "1px solid red" }
                  }
                  className="border-[1px] border-[#D9D9DE] w-[140px]  min-h-[32px] rounded-[4px]"
                >
                  <SelectBox
                    value={values.visitedType}
                    setFieldValue={setFieldValue}
                    name={"visitedType"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={visitedTypeOptions}
                    customClass={"select-29"}
                    isBorderPadding={false}
                    isHidden={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"buttons"}>
        <button onClick={onSubmit} className={"button-1"}>
          <div className={"next"}>Next</div>
        </button>
      </div>
    </div>
  );
};
export default Root;
