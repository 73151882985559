import React from "react";

const DateField = ({
  value,
  min,
  max,
  customClass,
  name,
  onBlur,
  onChange,
  important,
  label,
}) => {
  return (
    <div className="flex items-center  w-full">
      <label
        className={`block text-sm ${
          important ? "after:content-['*'] after:ml-0.5 after:text-red-500" : ""
        } text-gray-700 font-medium`}
        htmlFor="name"
      >
        {label}
      </label>
      <input
      style={value?{color:"#000",opacity:1}:{}}
        value={value}
        min={min}
        max={max}
        type="date"
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        placeholder={"dd/mm/yy"}
        className={` ${customClass} bg-transparent outline-none  rounded-sm `}
      />
    </div>
  );
};

export default DateField;
