import React, { useEffect } from "react";
// import "./global.scss";
// import "./index.scss";
import "../styles/4.scss";
import FormInput from "./common/FormInput";
import SelectBox from "./common/SelectBox";
import { ReactComponent as SelectedCircle } from "../assets/icon/selectedCircle.svg";
import { ReactComponent as CompletededCircle } from "../assets/icon/completedCircle.svg";
import { ReactComponent as NotSelectdCircle } from "../assets/icon/unselectCircle.svg";
import MenuIcon from "../assets/icon/menu-fill@1x.svg";
import DashboardIcon from "../assets/icon/dashboard-fill@1x.svg";
import PieChartIcon from "../assets/icon/pie-chart-2-fill@1x.svg";
import HeartIcon from "../assets/icon/heart-3-fill@1x.svg";
import MailIcon from "../assets/icon/mail-fill@1x.svg";
import CameraIcon from "../assets/icon/camera@1x.svg";
import MoreIcon from "../assets/icon/more-Icon@1x.svg";
import { ReactComponent as SelectedBar } from "../assets/icon/selectedBar.svg";
import { ReactComponent as UnSelectedBar } from "../assets/icon/unselectBar.svg";

const Contact = ({
  values,
  onSubmit,
  setTab,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  errors,
  completedTab,
  selectedTab
}) => {
  React.useEffect(() => {
    // Initialize the code
    return () => {};
  }, []);

  const handleTab=(v)=>{
    if(completedTab?.includes(v)&& Object.keys(errors)?.length===0){
      setTab(v)
    }
  }
  useEffect(()=>{
    if(selectedTab===4){

      onSubmit()
    }
  },[selectedTab])
  return (
    <div className={"android-large-4"}>
      <div className={"frame-2"}>
        <div className={"navbar"}>
          <div className={"menu-fill"}>
            <img src={MenuIcon} />
          </div>
          <div className={"dashboard-fill"}>
            <img src={DashboardIcon} />
          </div>
          <div className={"pie-chart-2-fill"}>
            <img src={PieChartIcon} />
          </div>
          <div className={"heart-3-fill"}>
            <img src={HeartIcon} />
          </div>
          <div className={"mail-fill"}>
            <img src={MailIcon} />
          </div>
          <div className={"more-fill"}>
            <img src={MoreIcon} />
          </div>
        </div>
        <div className={"navbar-1"}>
          <div className={"nav"}>
            <div className={"registration"}>Registration</div>
            <div className={"location"}>
              <svg id="7:91720" className={"icon-1"}></svg>
              <div className={"text-102038161"}>10.20.38.161</div>
            </div>
          </div>
          <div className={"search"}>
            <div className={"placeholder"}>
              <div className={"camera-fill"}>
                <img src={CameraIcon} />
              </div>
              <div className={"divider"}></div>
              <div className={"search-for-document"}>Search for document</div>
            </div>
            <div className={"button"}>
              <div className={"search-1"}>Search</div>
            </div>
          </div>
        </div>
        <div className={"steps"}>
          <div onClick={()=>handleTab(1)} className={"step"}>
            <div className={"basic"}>Basic</div>
            {
              completedTab.includes(1)?
              <CompletededCircle/>:
            
            <div className={"number"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(1)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(2)} className={"step-1"}>
            <div className={"referral"}>Referral</div>
            {
              completedTab.includes(2)?
              <CompletededCircle/>:
            
            <div className={"number"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(2)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div className={"step-2"}>
            <div onClick={()=>handleTab(3)} className={"address"}>Address</div>
            {
              completedTab.includes(3)?
              <CompletededCircle/>:
            <div className={"number"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
          completedTab.includes(3)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div className={"step-3"}>
            <div className={"contact"}>Contact</div>
            {
            Object.keys(errors).length ===0?
            <CompletededCircle/>:
            <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] ">
          {
              Object.keys(errors).length === 0&&completedTab.includes(4)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(5)} className={"step-4"}>
            <div className={"payment"}>Payment</div>
            {
              completedTab.includes(5)?
              <CompletededCircle/>:
            <div className={"number-1"}></div>}
          </div>
        </div>
        <div className={"tabs"}>
          <div className={"itemchecked"}>
            <div className={"contact-details"}>Contact Details</div>
            <div className={"checked"}></div>
          </div>
          <svg id="7:91812" className={"frame-3441"}></svg>
        </div>
        <div className={"form"}>
          <div className={"frame-3439"}>
            <div className={"form-1"}>
              <div
                style={
                  errors.mobileOne &&
                  touched.mobileOne && { borderBottom: " 1px solid red" }
                }
                className={"formitem"}
              >
                <div className={"name"}>
                  <div className={"text after:content-['*'] after:ml-0.5 after:text-red-500"}>Mobile 1</div>
                  <FormInput
                    type={"number"}
                    maxLength={10}
                    value={values.mobileOne}
                    name={"mobileOne"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.mobileTwo &&
                  touched.mobileTwo && { borderBottom: " 1px solid red" }
                }
                className={"formitem-1"}
              >
                <div className={"name-1"}>
                  <div className={"text-1"}>Mobile 2</div>
                  <FormInput
                    type={"number"}
                    maxLength={10}
                    value={values.mobileTwo}
                    name={"mobileTwo"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-1"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.telephone &&
                  touched.telephone && { borderBottom: " 1px solid red" }
                }
                className={"formitem-2"}
              >
                <div className={"name-2"}>
                  <div className={"text-2"}>Telephone</div>
                  <FormInput
                    type={"number"}
                    maxLength={10}
                    value={values.telephone}
                    name={"telephone"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-2"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.email &&
                  touched.email && { borderBottom: " 1px solid red" }
                }
                className={"formitem-3"}
              >
                <div className={"name-3"}>
                  <div className={"text-3"}>Email Id</div>
                  <FormInput
                    value={values.email}
                    name={"email"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-3"}
                  />
                </div>
              </div>
              <div
                style={
                  errors.contactVia &&
                  touched.contactVia && { borderBottom: " 1px solid red" }
                }
                className={"formitem-4"}
              >
                <div className={"select"}>Contact Via</div>
                <SelectBox
                  value={values.contactVia}
                  setFieldValue={setFieldValue}
                  name={"contactVia"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={[
                    { value: "email", label: "Email" },
                    { value: "phone", label: "Phone" },
                  ]}
                  isBorderPadding={false}
                  customClass={"select-1"}
                />
              </div>
              <div
                style={
                  errors.nearestPs &&
                  touched.nearestPs && { borderBottom: " 1px solid red" }
                }
                className={"formitem-5"}
              >
                <div className={"name-4"}>
                  <div className={"text-4"}>Nearest PS</div>
                  <FormInput
                    value={values.nearestPs}
                    name={"nearestPs"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-4"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"buttons"}>
        <div onClick={() => setTab(3)} className={"button-1"}>
          <div className={"back"}>Back</div>
        </div>
        <div onClick={onSubmit} className={"button-2"}>
          <div className={"next"}>Next</div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
