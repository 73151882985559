import React, { useEffect, useState } from "react";
// import "./global.scss";
// import "./index.scss";
import "../styles/2.scss";
import SelectBox from "./common/SelectBox";
import FormInput from "./common/FormInput";
import { ReactComponent as SelectedCircle } from "../assets/icon/selectedCircle.svg";
import { ReactComponent as CompletededCircle } from "../assets/icon/completedCircle.svg";
import { ReactComponent as NotSelectdCircle } from "../assets/icon/unselectCircle.svg";
import MenuIcon from "../assets/icon/menu-fill@1x.svg";
import DashboardIcon from "../assets/icon/dashboard-fill@1x.svg";
import PieChartIcon from "../assets/icon/pie-chart-2-fill@1x.svg";
import HeartIcon from "../assets/icon/heart-3-fill@1x.svg";
import MailIcon from "../assets/icon/mail-fill@1x.svg";
import CameraIcon from "../assets/icon/camera@1x.svg";
import MoreIcon from "../assets/icon/more-Icon@1x.svg";
import { referralType } from "../data/selectData";
import TextArea from "./common/TextArea";
import { ReactComponent as SelectedBar } from "../assets/icon/selectedBar.svg";
import { ReactComponent as UnSelectedBar } from "../assets/icon/unselectBar.svg";

const Referral = ({
  values,
  onSubmit,
  headerTab,
  setTab,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  errors,
  completedTab,
  selectedTab
}) => {
  React.useEffect(() => {
    // Initialize the code
    return () => {};
  }, []);
  const [referralTab, setReferralTab] = useState("r1");
  const [referralData, setreferralData] = useState({});

  useEffect(() => {
    if (referralTab === "r1") {
      setreferralData(values.rOne);
    } else if (referralTab === "r2") {
      setreferralData(values.rTwo);
    } else if (referralTab === "r3") {
      setreferralData(values.rThree);
    } else if (referralTab === "r4") {
      setreferralData(values.rFour);
    }
  }, [referralTab]);

  const handleReferalData = (v, type) => {
    
    if (referralTab === "r1") {
      setFieldValue("rOne", { ...values.rOne, [type]: v });
    } else if (referralTab === "r2") {
      setFieldValue("rTwo", { ...values.rTwo, [type]: v });
    } else if (referralTab === "r3") {
      setFieldValue("rThree", { ...values.rThree, [type]: v });
    } else if (referralTab === "r4") {
      setFieldValue("rFour", { ...values.rFour, [type]: v });
    }
  };

  const getValue = (type) => {
   
    if (referralTab === "r1") {
      return values.rOne[type];
    } else if (referralTab === "r2") {
      return values["rTwo"][type];
    } else if (referralTab === "r3") {
      return values["rThree"][type];
    } else if (referralTab === "r4") {
      return values["rFour"][type];
    }
  };

  const handleTab=(v)=>{
    if(completedTab?.includes(v)&& Object.keys(errors)?.length===0){
      setTab(v)
    }
  }

  // useEffect(()=>{
  //   if(selectedTab===2){

  //     onSubmit()
  //   }
  // },[selectedTab])
  return (
    <div className={"android-large-2"}>
      <div className={"frame-2"}>
        <div className={"navbar"}>
          <div className={"menu-fill"}>
            <img src={MenuIcon} />
          </div>
          <div className={"dashboard-fill"}>
            <img src={DashboardIcon} />
          </div>
          <div className={"pie-chart-2-fill"}>
            <img src={PieChartIcon} />
          </div>
          <div className={"heart-3-fill"}>
            <img src={HeartIcon} />
          </div>
          <div className={"mail-fill"}>
            <img src={MailIcon} />
          </div>
          <div className={"more-fill"}>
            <img src={MoreIcon} />
          </div>
        </div>
        <div className={"navbar-1"}>
          <div className={"nav"}>
            <div className={"registration"}>Registration</div>
            <div className={"location"}>
              <svg id="7:84260" className={"icon-1"}></svg>
              <div className={"text-102038161"}>10.20.38.161</div>
            </div>
          </div>
          <div className={"search"}>
            <div className={"placeholder"}>
              <div className={"camera-fill"}>
                <img src={CameraIcon} />
              </div>
              <div className={"divider"}></div>
              <div className={"search-for-document"}>Search for document</div>
            </div>
            <div className={"button"}>
              <div className={"search-1"}>Search</div>
            </div>
          </div>
        </div>
        <div className={"steps"}>
          <div onClick={()=>handleTab(1)} className={"step"}>
            <div  className={"basic"}>Basic</div>
            {
              completedTab.includes(1)?
              <CompletededCircle/>:
              <div className={"number"}></div>}

          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(1)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div className={"step-1"}>
            <div className={"referral"}>Referral</div>
            {
              Object.keys(errors).length === 0?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] ">
          {
              Object.keys(errors).length === 0&&completedTab.includes(2)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(3)} className={"step-2"}>
            <div className={"address"}>Address</div>
            {
              completedTab.includes(3)?
              <CompletededCircle/>:
            <div className={"number-1"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(3)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(4)} className={"step-3"}>
            <div className={"contact"}>Contact</div>
            {
              completedTab.includes(4)?
              <CompletededCircle/>:
            <div className={"number-2"}></div>}
          </div>
          <div className=" h-[29%] ">
          {
              completedTab.includes(4)?
          <SelectedBar/>:
          <UnSelectedBar/>
        }
            </div>
          <div onClick={()=>handleTab(5)} className={"step-4"}>
            <div className={"payment"}>Payment</div>
            {
              completedTab.includes(5)?
              <CompletededCircle/>:
            <div className={"number-3"}></div>}
          </div>
        </div>
        <div className={"tabs"}>
          <div
            onClick={() => setReferralTab("r1")}
            className={referralTab === "r1" ? "itemchecked" : "itemnormal"}
          >
            <div className={"r2"}>R1</div>
            {referralTab === "r1" && <div className={"checked"}></div>}
          </div>
          <div
            onClick={() => setReferralTab("r2")}
            className={referralTab === "r2" ? "itemchecked" : "itemnormal"}
          >
            <div className={"r2"}>R2</div>
            {referralTab === "r2" && <div className={"checked"}></div>}
          </div>
          <div
            onClick={() => setReferralTab("r3")}
            className={referralTab === "r3" ? "itemchecked" : "itemnormal"}
          >
            <div className={"r2"}>R3</div>
            {referralTab === "r3" && <div className={"checked"}></div>}
          </div>
          <div
            onClick={() => setReferralTab("r4")}
            className={referralTab === "r4" ? "itemchecked" : "itemnormal"}
          >
            <div className={"r2"}>R4</div>
            {referralTab === "r4" && <div className={"checked"}></div>}
          </div>
          <svg id="7:90754" className={"frame-3441"}></svg>
        </div>
        <div className={"form"}>
          <div className={"frame-3439"}>
            <div className={"form-1"}>
              <div className={"formitem"}>
                <div className={"select"}>Referal Type</div>
                <SelectBox
                  value={getValue("referal_type")}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={(e) =>
                    handleReferalData(e.target.value, "referal_type")
                  }
                  options={referralType}
                  name={"referal_type"}
                  customClass={"select-1"}
                  isBorderPadding={false}
                  isHidden={true}
                />
              </div>
              <div className={"formitem-1"}>
                <div className={"name"}>
                  <div className={"text"}>Referred by</div>
                  <FormInput
                    value={getValue("referred_by")}
                    name={"referred_by"}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={(e) =>
                      handleReferalData(e.target.value, "referred_by")
                    }
                    isBorderPadding={true}
                    customClass={"enter"}
                  />
                </div>
              </div>
              <div className={"formitem-2"}>
                <div className={"name-1"}>
                  <div className={"text-1"}>Source</div>
                  <FormInput
                    value={getValue("source")}
                    name={"source"}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={(e) =>
                      handleReferalData(e.target.value, "source")
                    }
                    isBorderPadding={true}
                    customClass={"enter-1"}
                  />
                </div>
              </div>
              <div className={"formitem-3"}>
                <div className={"name-2"}>
                  <div className={"text-2"}>Referred To</div>
                  <FormInput
                    value={getValue("referred_to")}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={(e) =>
                      handleReferalData(e.target.value, "referred_to")
                    }
                    isBorderPadding={true}
                    customClass={"enter-2"}
                  />
                </div>
              </div>
              <div className={"formitem-4"}>
                <div className={"select-2"}>Address</div>
                <FormInput
                  value={getValue("address")}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={(e) => handleReferalData(e.target.value, "address")}
                  isBorderPadding={true}
                  customClass={"select-3"}
                />
              </div>
              <div className={"formitem-5"}>
                <div className={"select-4"}>Phone</div>
                <FormInput
                  type={"number"}
                  maxLength={10}
                  value={getValue("phone")}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  onChange={(e) => handleReferalData(e.target.value, "phone")}
                  isBorderPadding={true}
                  customClass={"select-5"}
                />
              </div>
              <div className={"formitem-6"}>
                <div className={"textarea"}>Remark</div>
                <div className={"input"}>
                  <TextArea
                    maxLength={200}
                    value={getValue("remark")}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    onChange={(e) =>
                      handleReferalData(e.target.value, "remark")
                    }
                    isBorderPadding={true}
                    customClass={"enter-3"}
                  />

                  <div className={"text-0-200"}>
                    {getValue("remark")?.length}/200
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"buttons"}>
        <button onClick={() => setTab(1)} className={"button-1"}>
          <div className={"back"}>Back</div>
        </button>
        <div onClick={onSubmit} className={"button-2"}>
          <div className={"next"}>Next</div>
        </div>
      </div>
    </div>
  );
};
export default Referral;
