import React, { useEffect } from "react";
// import "./global.scss";
// import "./index.scss";
import "../styles/5.scss";
import FormInput from "./common/FormInput";
import DateField from "./common/DateField";
import SelectBox from "./common/SelectBox";
import { ReactComponent as SelectedCircle } from "../assets/icon/selectedCircle.svg";
import { ReactComponent as CompletededCircle } from "../assets/icon/completedCircle.svg";
import { ReactComponent as NotSelectdCircle } from "../assets/icon/unselectCircle.svg";
import { bankOptionsIndia, cardTypeOptions } from "../data/selectData";
import MenuIcon from "../assets/icon/menu-fill@1x.svg";
import DashboardIcon from "../assets/icon/dashboard-fill@1x.svg";
import PieChartIcon from "../assets/icon/pie-chart-2-fill@1x.svg";
import HeartIcon from "../assets/icon/heart-3-fill@1x.svg";
import MailIcon from "../assets/icon/mail-fill@1x.svg";
import CameraIcon from "../assets/icon/camera@1x.svg";
import MoreIcon from "../assets/icon/more-Icon@1x.svg";
import TextArea from "./common/TextArea";
import { ReactComponent as SelectedBar } from "../assets/icon/selectedBar.svg";
import { ReactComponent as UnSelectedBar } from "../assets/icon/unselectBar.svg";

const Payment = ({
  values,
  onSubmit,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  errors,
  completedTab,
  setTab,
  selectedTab
}) => {
  React.useEffect(() => {
    // Initialize the code
    return () => {};
  }, []);

  const handleTab=(v)=>{
    if(completedTab?.includes(v) && Object.keys(errors)?.length===0){

      setTab(v)
    }
  }
  useEffect(()=>{
    if(selectedTab===5){

      onSubmit()
    }
  },[selectedTab])
  return (
    <div className={"android-large-5"}>
      <div className={"frame-2"}>
        <div className={"navbar"}>
          <div className={"menu-fill"}>
            <img src={MenuIcon} />
          </div>
          <div className={"dashboard-fill"}>
            <img src={DashboardIcon} />
          </div>
          <div className={"pie-chart-2-fill"}>
            <img src={PieChartIcon} />
          </div>
          <div className={"heart-3-fill"}>
            <img src={HeartIcon} />
          </div>
          <div className={"mail-fill"}>
            <img src={MailIcon} />
          </div>
          <div className={"more-fill"}>
            <img src={MoreIcon} />
          </div>
        </div>
        <div className={"navbar-1"}>
          <div className={"nav"}>
            <div className={"registration"}>Registration</div>
            <div className={"location"}>
              <svg id="7:92724" className={"icon-1"}></svg>
              <div className={"text-102038161"}>10.20.38.161</div>
            </div>
          </div>
          <div className={"search"}>
            <div className={"placeholder"}>
              <div className={"camera-fill"}>
                <img src={CameraIcon} />
              </div>
              <div className={"divider"}></div>
              <div className={"search-for-document"}>Search for document</div>
            </div>
            <div className={"button"}>
              <div className={"search-1"}>Search</div>
            </div>
          </div>
        </div>
        <div className={"steps"}>
          <div onClick={()=>handleTab(1)} className={"step"}>
            <div  className={"basic"}>Basic</div>
            {
              completedTab.includes(1)?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] "><SelectedBar/></div>
          <div onClick={()=>handleTab(2)} className={"step-1"}>
            <div className={"referral"}>Referral</div>
            {
              completedTab.includes(2)?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] "><SelectedBar/></div>
          <div onClick={()=>handleTab(3)} className={"step-2"}>
            <div className={"address"}>Address</div>
            {
              completedTab.includes(3)?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] "><SelectedBar/></div>
          <div onClick={()=>handleTab(4)} className={"step-3"}>
            <div className={"contact"}>Contact</div>
            {
              completedTab.includes(4)?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
          </div>
          <div className=" h-[29%] "><SelectedBar/></div>
          <div className={"step-4"}>
            <div className={"payment"}>Payment</div>
            {
              Object.keys(errors)?.length===0?
              <CompletededCircle/>:
              <div className={"number"}></div>
            }
            
          </div>
        </div>
        <div className={"tabs"}>
          <div className={"itemchecked"}>
            <div className={"quick"}>Quick</div>
            <div className={"checked"}></div>
          </div>
          {/* <div className={'itemnormal'}>
                <div className={'advanced'}>
                    Advanced
                </div>
            </div> */}
          <svg id="7:92818" className={"frame-3441"}></svg>
        </div>
        <div className={"form"}>
          <div className={"table"}>
            <div className={"content"}>
              <div className={"cell"}>
                <div className={"bill-info"}>Bill info</div>
              </div>
              <div className={"cell-1"}>
                <div className={"gross"}>Gross</div>
              </div>
              <div className={"cell-2"}>
                <div className={"tax"}>Tax</div>
              </div>
              <div className={"cell-3"}>
                <div className={"due"}>Due</div>
              </div>
            </div>
            <div className={"line-4"}></div>
            <div className={"content-1"}>
              <div className={"cell-4"}>
                <div className={"patient"}>Patient</div>
              </div>
              <div className={"cell-5"}>
                <div className={"text-200"}>200</div>
              </div>
              <div className={"cell-6"}>
                <div className={"text"}></div>
              </div>
              <div className={"cell-7"}>
                <div className={"text-201"}>200</div>
              </div>
            </div>
            <div className={"line-5"}></div>
            <div className={"content-2"}>
              <div className={"cell-8"}>
                <div className={"party"}>Party</div>
              </div>
              <div className={"cell-9"}>
                <div className={"text-0"}>0</div>
              </div>
              <div className={"cell-10"}>
                <div className={"text-1"}></div>
              </div>
              <div className={"cell-11"}>
                <div className={"text-2"}>0</div>
              </div>
            </div>
            <div className={"line-6"}></div>
            <div className={"content-3"}>
              <div className={"cell-12"}>
                <div className={"total"}>Total</div>
              </div>
              <div className={"cell-13"}>
                <div className={"text-202"}>200</div>
              </div>
              <div className={"cell-14"}>
                <div className={"text-3"}></div>
              </div>
              <div className={"cell-15"}>
                <div className={"text-203"}>200</div>
              </div>
            </div>
          </div>
          <div className={"input"}>
            <div className={"due-auth-pat"}>Due Auth. Pat</div>
            <FormInput
              value={values.dueAuthAndPat}
              name={"dueAuthAndPat"}
              onChange={handleChange}
              onBlur={handleBlur}
              isBorderPadding={true}
              customClass={"input-1 text-4"}
            />
          </div>
          <div className={"frame-3439"}>
            <div className={"receipt-info"}>Receipt Info</div>
            <div className={"form-1"}>
              <div className={"formitem"}>
                <div className={"name"}>
                  <div className={"text-5"}>Receipt</div>
                  <FormInput
                    value={values.receiptHashTag}
                    name={"receiptHashTag"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter"}
                  />
                </div>
              </div>
              <div style={(errors.receiptDate&&touched.receiptDate)&&{borderBottom:"1px solid red"}} className={"formitem-1"}>
                <div className={"name-1"}>
                  <div className={"text-6 after:content-['*'] after:ml-0.5 after:text-red-500"}>Receipt Dt</div>
                  <DateField
                    value={values.receiptDate}
                    name={"receiptDate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"enter-1"}
                  />
                </div>
              </div>
              <div className={"formitem-2"}>
                <div className={"name-2"}>
                  <div className={"text-7"}>Cash Amt</div>
                  <FormInput
                    type={"number"}
                    value={values.cashAmount}
                    name={"cashAmount"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-2"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"frame-3440"}>
            <div className={"card-details"}>Card Details</div>
            <div className={"form-2"}>
              <div style={(errors.cardType&&touched.cardType)&&{borderBottom:"1px solid red"}} className={"formitem-3"}>
                <div className={"select after:content-['*'] after:ml-0.5 after:text-red-500"}>Card Type</div>
                <SelectBox
                  value={values.cardType}
                  name={"cardType"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={cardTypeOptions}
                  isBorderPadding={false}
                  customClass={"select-1"}
                />
              </div>
              <div className={"formitem-4"}>
                <div className={"name-3"}>
                  <div className={"text-8"}>Card Number</div>
                  <FormInput
                    type={"number"}
                    value={values.cardHashTag}
                    name={"cardHashTag"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-3"}
                  />
                </div>
              </div>
              <div style={(errors.bankName&&touched.bankName)&&{borderBottom:"1px solid red"}} className={"formitem-5"}>
                <div className={"select-2 after:content-['*'] after:ml-0.5 after:text-red-500"}>Bank Name</div>
                <SelectBox
                  value={values.bankName}
                  setFieldValue={setFieldValue}
                  name={"bankName"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={bankOptionsIndia}
                  isBorderPadding={false}
                  customClass={"select-3"}
                />
              </div>
              <div style={(errors.cardExpDate&&touched.cardExpDate)&&{borderBottom:"1px solid red"}} className={"formitem-6"}>
                <div className={"name-4"}>
                  <div className={"text-9 after:content-['*'] after:ml-0.5 after:text-red-500"}>Exp. Date</div>
                  <DateField
                    value={values.cardExpDate}
                    name={"cardExpDate"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    customClass={"enter-4"}
                  />
                </div>
              </div>
              <div style={(errors.cardHolderName&&touched.cardHolderName)&&{borderBottom:"1px solid red"}} className={"formitem-7"}>
                <div className={"name-5"}>
                  <div className={"text-10 after:content-['*'] after:ml-0.5 after:text-red-500"}>Name on Card</div>
                  <FormInput
                    value={values.cardHolderName}
                    name={"cardHolderName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-5"}
                  />
                </div>
              </div>
              <div className={"formitem-8"}>
                <div className={"name-6"}>
                  <div className={"text-11"}>Amount</div>
                  <FormInput
                    type={"number"}
                    value={values.cashAmount}
                    name={"cashAmount"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-6"}
                  />
                </div>
              </div>
              <div className={"formitem-9"}>
                <div className={"name-7"}>
                  <div className={"text-12"}>Auth.</div>
                  <FormInput
                    value={values.authHash}
                    name={"authHash"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-7"}
                  />
                </div>
              </div>
              <div className={"formitem-10"}>
                <div className={"textarea"}>Remarks</div>
                <div className={"input-2"}>
                  <TextArea
                    value={values.remarks}
                    name={"remarks"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"enter-8"}
                  />
                  <div className={"text-0-200"}>
                    {values.remarks?.length}/200
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"details"}>
            <div className={"listitem"}>
              <div className={"change-in-inr"}>Change in INR</div>
              <div className={"price"}>
                <div className={"text-13"}>0</div>
              </div>
            </div>
            <div className={"listitem-1"}>
              <div className={"change-amount-in-words"}>
                Change amount in words
              </div>
              <div className={"price-1"}>
                <div className={"text-14"}>0</div>
              </div>
            </div>
            <div className={"listitem-2"}>
              <div className={"total-receipt-amount-in-words"}>
                Total Receipt amount in words
              </div>
              <div className={"price-2"}>
                <div className={"visa"}>Visa</div>
              </div>
            </div>
            <div className={"listitem-3"}>
              <div className={"cash-amount-in-words"}>Cash amount in words</div>
              <div className={"price-3"}>
                <div className={"text-"}>₹</div>
                <div className={"text-39"}>39</div>
              </div>
            </div>
            <div className={"listitem-4"}>
              <div className={"card-amount-in-words"}>Card amount in words</div>
              <div className={"price-4"}>
                <div className={"text--1"}>₹</div>
                <div className={"text-15"}>10</div>
              </div>
            </div>
            <div className={"listitem-5"}>
              <div className={"total-1"}>Total</div>
              <div className={"price-5"}>
                <div className={"text--2"}>₹</div>
                <div className={"text-49"}>49</div>
              </div>
            </div>
          </div>
          <div className={"input-3"}>
            <div className={"notes"}>Notes</div>
            <TextArea
                    value={values.notes}
                    name={"notes"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isBorderPadding={true}
                    customClass={"input-4"}
                  />
          </div>
          <div onClick={onSubmit} className={"button-1"}>
            <div className={"submit"}>Submit</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Payment;
