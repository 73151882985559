import React from "react";
import Select from 'react-select';

const SelectBox = ({
  value,
  customClass,
  isHidden,
  onBlur,
  isBorderPadding,
  name,
  options,
  onChange,
  important,
  label,
  optionLabel,
  optionValue,
  setFieldValue,
  error,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      border: state.isFocused ? 'none' : provided.boxShadow,
      borderColor: state.isFocused ? 'none' : provided.borderColor,
      '&:hover': {
        borderColor: state.isFocused ? 'none' : provided['&:hover'].borderColor,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#3578ff'
        : state.isFocused
        ? '#3578ff'
        : provided.backgroundColor,
      color: state.isSelected ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: '#3578ff',
        color: '#333',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: 'Open Sans, sans-serif',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: 'Open Sans, sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    // Optionally, you can customize other parts of the component
    // as per your design requirements.
  };
  return (
    <>
    {/* <div className="flex items-center w-full  ">
      <label
        className={`${isHidden ? "hidden" : "block"}  text-sm ${
          important ? "after:content-['*'] after:ml-0.5 after:text-red-500" : ""
        } text-gray-700 font-medium`}
        htmlFor="name"
      >
        {label}
      </label>
      <select
        onBlur={onBlur}
        value={value}
        name={name}
        onChange={onChange}
        style={error ?{border:"1px solid red",color:value&&"#000",opacity:value&&1}:{color:value&&"#000",opacity:value&&1}}
        className={`${customClass}  outline-none w-full ${
          isBorderPadding
            ? "border-[1px]  border-gray-500"
            : "border-none bg-transparent p-0"
        }`}
      >
        <option value={""}>Select</option>
        {options?.map((val, i) => (
          <option key={i} value={val?.value}>
            {val?.label}
          </option>
        ))}
      </select>
    </div> */}
    <Select
        isMulti={false}
        // isDisabled={true}
        defaultValue={"Select"}
        onChange={(e)=>{
          setFieldValue(name,e.value)
          
        }}
        options={options}
        name={name}
        styles={customStyles}
        value={options?.find(val=>val.value===value)}
      />
    </>
  );
};

export default SelectBox;
