export const countries = [
    {label: "India", value: "india"},
    {label: "United States", value: "united_states"},
    {label: "Canada", value: "canada"},
    {label: "Australia", value: "australia"},
    {label: "United Kingdom", value: "united_kingdom"},
    {label: "Germany", value: "germany"},
    {label: "France", value: "france"},
    {label: "Japan", value: "japan"},
    {label: "China", value: "china"},
    {label: "Brazil", value: "brazil"},
    {label: "South Africa", value: "south_africa"},
    {label: "Russia", value: "russia"},
    {label: "Mexico", value: "mexico"},
    {label: "Italy", value: "italy"},
    {label: "Spain", value: "spain"}
];
export const statesOfIndia = [
    {label: "Andhra Pradesh", value: "andhra_pradesh"},
    {label: "Arunachal Pradesh", value: "arunachal_pradesh"},
    {label: "Assam", value: "assam"},
    {label: "Bihar", value: "bihar"},
    {label: "Chhattisgarh", value: "chhattisgarh"},
    {label: "Goa", value: "goa"},
    {label: "Gujarat", value: "gujarat"},
    {label: "Haryana", value: "haryana"},
    {label: "Himachal Pradesh", value: "himachal_pradesh"},
    {label: "Jharkhand", value: "jharkhand"},
    {label: "Karnataka", value: "karnataka"},
    {label: "Kerala", value: "kerala"},
    {label: "Madhya Pradesh", value: "madhya_pradesh"},
    {label: "Maharashtra", value: "maharashtra"},
    {label: "Manipur", value: "manipur"},
    {label: "Meghalaya", value: "meghalaya"},
    {label: "Mizoram", value: "mizoram"},
    {label: "Nagaland", value: "nagaland"},
    {label: "Odisha", value: "odisha"},
    {label: "Punjab", value: "punjab"},
    {label: "Rajasthan", value: "rajasthan"},
    {label: "Sikkim", value: "sikkim"},
    {label: "Tamil Nadu", value: "tamil_nadu"},
    {label: "Telangana", value: "telangana"},
    {label: "Tripura", value: "tripura"},
    {label: "Uttar Pradesh", value: "uttar_pradesh"},
    {label: "Uttarakhand", value: "uttarakhand"},
    {label: "West Bengal", value: "west_bengal"}
];
export const districtsOfOdisha = [
    {label: "Angul", value: "angul"},
    {label: "Balangir", value: "balangir"},
    {label: "Balasore", value: "balasore"},
    {label: "Bargarh", value: "bargarh"},
    {label: "Bhadrak", value: "bhadrak"},
    {label: "Boudh", value: "boudh"},
    {label: "Cuttack", value: "cuttack"},
    {label: "Deogarh", value: "deogarh"},
    {label: "Dhenkanal", value: "dhenkanal"},
    {label: "Gajapati", value: "gajapati"},
    {label: "Ganjam", value: "ganjam"},
    {label: "Jagatsinghpur", value: "jagatsinghpur"},
    {label: "Jajpur", value: "jajpur"},
    {label: "Jharsuguda", value: "jharsuguda"},
    {label: "Kalahandi", value: "kalahandi"},
    {label: "Kandhamal", value: "kandhamal"},
    {label: "Kendrapara", value: "kendrapara"},
    {label: "Kendujhar (Keonjhar)", value: "kendujhar"},
    {label: "Khordha", value: "khordha"},
    {label: "Koraput", value: "koraput"},
    {label: "Malkangiri", value: "malkangiri"},
    {label: "Mayurbhanj", value: "mayurbhanj"},
    {label: "Nabarangpur", value: "nabarangpur"},
    {label: "Nayagarh", value: "nayagarh"},
    {label: "Nuapada", value: "nuapada"},
    {label: "Puri", value: "puri"},
    {label: "Rayagada", value: "rayagada"},
    {label: "Sambalpur", value: "sambalpur"},
    {label: "Subarnapur (Sonepur)", value: "subarnapur"},
    {label: "Sundargarh", value: "sundargarh"}
];
export const citiesOfOdisha = [
    {label: "Bhubaneswar", value: "bhubaneswar"},
    {label: "Cuttack", value: "cuttack"},
    {label: "Rourkela", value: "rourkela"},
    {label: "Berhampur", value: "berhampur"},
    {label: "Sambalpur", value: "sambalpur"},
    {label: "Balasore", value: "balasore"},
    {label: "Baripada", value: "baripada"},
    {label: "Bhadrak", value: "bhadrak"},
    {label: "Puri", value: "puri"},
    {label: "Jeypore", value: "jeypore"},
    {label: "Balangir", value: "balangir"},
    {label: "Jharsuguda", value: "jharsuguda"},
    {label: "Bargarh", value: "bargarh"},
    {label: "Paradeep", value: "paradeep"},
    {label: "Angul", value: "angul"},
    {label: "Dhenkanal", value: "dhenkanal"},
    {label: "Kendrapara", value: "kendrapara"},
    {label: "Rayagada", value: "rayagada"},
    {label: "Kendujhar", value: "kendujhar"},
    {label: "Phulbani", value: "phulbani"}
];
export const regTypeOptions = [
    {label: "New Patient", value: "new_patient"},
    {label: "Returning Patient", value: "returning_patient"},
    {label: "Referral", value: "referral"},
    {label: "Emergency", value: "emergency"},
    {label: "Walk-In", value: "walk_in"},
    {label: "Online Appointment", value: "online_appointment"},
    {label: "Telephone Appointment", value: "telephone_appointment"},
    {label: "Follow-Up", value: "follow_up"},
    {label: "Specialist Consultation", value: "specialist_consultation"},
    {label: "Health Check-Up", value: "health_check_up"},
    {label: "Vaccination", value: "vaccination"},
    {label: "Diagnostic Test", value: "diagnostic_test"},
    {label: "Telemedicine", value: "telemedicine"},
    {label: "Home Visit", value: "home_visit"},
    {label: "Insurance Patient", value: "insurance_patient"},
    {label: "Corporate Health Program", value: "corporate_health_program"},
    {label: "Government Scheme", value: "government_scheme"}
];
export const cardTypeOptions = [
    {label: "Visa", value: "visa"},
    {label: "MasterCard", value: "mastercard"},
    {label: "American Express", value: "amex"},
    {label: "RuPay", value: "rupay"},
];

export const bankOptionsIndia = [
    {label: "State Bank of India (SBI)", value: "sbi"},
    {label: "HDFC Bank", value: "hdfc"},
    {label: "ICICI Bank", value: "icici"},
    {label: "Axis Bank", value: "axis"},
    {label: "Punjab National Bank (PNB)", value: "pnb"},
    {label: "Bank of Baroda (BoB)", value: "bob"},
    {label: "Canara Bank", value: "canara"},
    {label: "Union Bank of India", value: "union_bank"},
    {label: "IndusInd Bank", value: "indusind"},
    {label: "Kotak Mahindra Bank", value: "kotak"},
    {label: "IDBI Bank", value: "idbi"},
    {label: "Central Bank of India", value: "central_bank"},
    {label: "Bank of India (BOI)", value: "boi"},
    {label: "Indian Bank", value: "indian_bank"},
    {label: "Yes Bank", value: "yes_bank"},
    {label: "Federal Bank", value: "federal_bank"},
    {label: "Standard Chartered Bank", value: "standard_chartered"},
    {label: "HSBC Bank", value: "hsbc"},
    {label: "Citi Bank", value: "citi_bank"},
    {label: "DBS Bank", value: "dbs"}
];

export const patientCategoryOptions = [
    {label: "Inpatient", value: "inpatient"},
    {label: "Outpatient", value: "outpatient"},
    {label: "Emergency", value: "emergency"},
    {label: "Daycare", value: "daycare"},
    {label: "Specialty Clinic", value: "specialty_clinic"},
    {label: "Insurance", value: "insurance"},
    {label: "Self-pay", value: "self_pay"},
    {label: "Government Scheme", value: "government_scheme"},
    {label: "Corporate", value: "corporate"},
    {label: "Senior Citizen", value: "senior_citizen"},
    {label: "Child", value: "child"},
    {label: "Pregnant Woman", value: "pregnant_woman"},
    {label: "Disabled", value: "disabled"},
    {label: "Veteran", value: "veteran"},
    {label: "Cancer Patient", value: "cancer_patient"},
    {label: "HIV/AIDS Patient", value: "hiv_aids_patient"},
    {label: "Dialysis Patient", value: "dialysis_patient"},
    {label: "Physically Challenged", value: "physically_challenged"},
    {label: "Mental Health", value: "mental_health"},
    {label: "Other (Specify)", value: "other"}
];
export const occupationOptions = [
    {label: "Student", value: "student"},
    {label: "Teacher", value: "teacher"},
    {label: "Engineer", value: "engineer"},
    {label: "Doctor", value: "doctor"},
    {label: "Nurse", value: "nurse"},
    {label: "Accountant", value: "accountant"},
    {label: "Business Owner", value: "business_owner"},
    {label: "Lawyer", value: "lawyer"},
    {label: "Homemaker", value: "homemaker"},
    {label: "Retired", value: "retired"},
    {label: "Government Employee", value: "government_employee"},
    {label: "IT Professional", value: "it_professional"},
    {label: "Salesperson", value: "salesperson"},
    {label: "Artist", value: "artist"},
    {label: "Freelancer", value: "freelancer"}
];
export const nationalityOptions = [
    {label: "Indian", value: "indian"},
    {label: "American", value: "american"},
    {label: "British", value: "british"},
    {label: "Canadian", value: "canadian"},
    {label: "Australian", value: "australian"},
    {label: "German", value: "german"},
    {label: "French", value: "french"},
    {label: "Japanese", value: "japanese"},
    {label: "Chinese", value: "chinese"},
    {label: "Brazilian", value: "brazilian"},
    {label: "South African", value: "south_african"},
    {label: "Russian", value: "russian"},
    {label: "Mexican", value: "mexican"},
    {label: "Italian", value: "italian"},
    {label: "Spanish", value: "spanish"},
    {label: "Korean", value: "korean"},
    {label: "Singaporean", value: "singaporean"},
    {label: "Emirati", value: "emirati"},
    {label: "Saudi", value: "saudi"},
    {label: "Qatari", value: "qatari"}
];
export const raceOptions = [
    {label: "White / Caucasian", value: "white_caucasian"},
    {label: "Black / African American", value: "black_african_american"},
    {label: "Asian", value: "asian"},
    {label: "Hispanic / Latino", value: "hispanic_latino"},
    {label: "Native American / Alaska Native", value: "native_american_alaska_native"},
    {label: "Native Hawaiian / Pacific Islander", value: "native_hawaiian_pacific_islander"},
    {label: "Mixed Race", value: "mixed_race"},
    {label: "Other (Specify)", value: "other"}
];
export const religionOptions = [
    {label: "Christianity", value: "christianity"},
    {label: "Islam", value: "islam"},
    {label: "Hinduism", value: "hinduism"},
    {label: "Buddhism", value: "buddhism"},
    {label: "Judaism", value: "judaism"}
];


export const idProofOptions = [
    {label: "Aadhaar Card", value: "aadhaar_card"},
    {label: "PAN Card", value: "pan_card"},
    {label: "Passport", value: "passport"},
    {label: "Voter ID", value: "voter_id"},
    {label: "Driving License", value: "driving_license"}
];

export const umrOptions = [
    {label: "Aadhaar Number", value: "aadhaar_number"},
    {label: "Passport Number", value: "passport_number"},
    {label: "Driver's License Number", value: "drivers_license_number"},
    {label: "PAN Card Number", value: "pan_card_number"},
    {label: "Voter ID Number", value: "voter_id_number"},
    {label: "Employee ID", value: "employee_id"},
    {label: "Health Insurance ID", value: "health_insurance_id"},
    {label: "Government Issued ID", value: "government_issued_id"},
    {label: "National Health ID", value: "national_health_id"},
    {label: "Social Security Number", value: "social_security_number"}
];

export const visitedTypeOptions=[
    { label: "New Patient Visit", value: "new_patient" },
    { label: "Follow-Up Visit", value: "follow_up" },
    { label: "Annual Checkup", value: "checkup" },
    { label: "Urgent Care", value: "urgent_care" },
    { label: "Telehealth Appointment", value: "telehealth" }
  ]
  export const referralType = [
    { label: "Primary Care Physician", value: "primary_care_physician" },
    { label: "Specialist", value: "specialist" },
    { label: "Emergency Room", value: "emergency_room" },
    { label: "Self Referral", value: "self_referral" },
    { label: "Walk-in Clinic", value: "walk_in_clinic" },
    { label: "Telehealth", value: "telehealth" },
  ];

  export const referBy = [
    { label: "City Hospital", value: "city_hospital" },
    { label: "General Hospital", value: "general_hospital" },
    { label: "County Medical Center", value: "county_medical_center" },
    { label: "Saint Mary's Hospital", value: "saint_marys_hospital" },
    { label: "University Hospital", value: "university_hospital" },
    { label: "Children's Hospital", value: "childrens_hospital" },
    { label: "Regional Health Center", value: "regional_health_center" },
    { label: "Memorial Hospital", value: "memorial_hospital" }
  ];



