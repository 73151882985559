import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import "../../styles/1.scss";

import { ReactComponent as SelectedCircle } from "../../assets/icon/selectedCircle.svg";
import { ReactComponent as CompletededCircle } from "../../assets/icon/completedCircle.svg";
import { ReactComponent as NotSelectdCircle } from "../../assets/icon/unselectCircle.svg";
import { ReactComponent as SelectedBar } from "../../assets/icon/selectedBar.svg";
import { ReactComponent as UnSelectedBar } from "../../assets/icon/unselectBar.svg";
import Root from "../page1";
import Referral from "../2";
import Address from "../3";
import Contact from "../4";
import Payment from "../5";
import axios from "axios";
// import axios from "axios";

const MobileView = () => {
  const [selectedTab, setselectedTab] = useState(1);
  const [completedTab, setcompletedTab] = useState([1])
  const titles = [
    { tag: "mr", name: "Mr" },
    { tag: "mrs", name: "Mrs" },
    { tag: "smt", name: "Smt" },
  ];
  const initiaValues = {
    notes: "",
    title: "mr",
    date: "",
    kinName: "",
    religion: "",
    patientType: "mlc",
    bloodGroup: "",
    passport: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    occupation: "",
    idProof: "",
    idProofType: "",
    howYouHear: "",
    issueDate: "",
    expireDate: "",
    fathersName: "",
    maritalStatus: "",
    race: "",
    mothersName: "",
    patCategory: "",
    nationality: "",
    doctor: "",
    familyRef: "",
    issuedAt: "",
    regularVipVvip: "regular",
    regType: "",
    regFee: "",
    regExp: "",
    umr: "",
    regDate: "",
    visitedType: "",
    referType: "",
    referBy: "",
    referSource: "",
    referTo: "",
    address: "",
    phone: "",
    remarks: "",
    presentAddress: "",
    presentAddress2: "",
    permanentAddress: "",
    permanentAddress2: "",
    presentArea: "",
    permanentArea: "",
    presentCity: "",
    permanentCity: "",
    presentState: "",
    permanentState: "",
    presentZip: "",
    permanentZip: "",
    presentDistrict: "",
    permanentDistrict: "",
    presentCounty: "",
    permanentCounty: "",
    mobileOne: "",
    mobileTwo: "",
    telephone: "",
    email: "",
    contactVia: "",
    nearestPs: "",
    patientGross: "",
    partyGross: "",
    patientTax: "",
    patientDue: "",
    partyDue: "",
    dueAuthAndPat: "",
    receiptHashTag: "",
    cashAmount: "",
    cardType: "",
    cardHashTag: "",
    cardAmount: "",
    authHash: "",
    bankName: "",
    receiptDate: "",
    cardExpDate: "",
    cardHolderName: "",
    rOne: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    rTwo: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    rThree: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    rFour: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
  };
  const validationSchema = Yup.object({
    // RADIO BUTTON
    regularVipVvip: Yup.string().required("type"),

    //INPUT FIELD
    title: Yup.string().trim().required("title"),
    date: Yup.string().trim().required("date"),
    passport: Yup.string().required("passport"),
    firstName: Yup.string().required("firstName"),
    lastName: Yup.string().required("lastName"),
    fathersName: Yup.string().required("fathersName"),
    mothersName: Yup.string().required("mothersName"),
    idProof: Yup.string().required("idProof"),
    doctor: Yup.string().required("doctor"),
    familyRef: Yup.string().required("familyRef"),
    issuedAt: Yup.string().required("issuedAt"),
    regFee: Yup.string().required("regFee"),
    referBy: Yup.string().required("referBy"),
    referSource: Yup.string().required("referBy"),
    referTo: Yup.string().required("referTo"),
    email: Yup.string().required("email"),
    nearestPs: Yup.string().required("nearestPs"),

    receiptHashTag: Yup.string().required("receiptHashTag"),
    cashAmount: Yup.string().required("cashAmount"),
    cardHashTag: Yup.string().required("cardHashTag"),
    authHash: Yup.string().required("authHash"),
    receiptDate: Yup.string().required("receiptDate"),
    cardExpDate: Yup.string().required("cardExpDate"),
    cardHolderName: Yup.string().required("cardHolderName"),

    // NUMBER

    mobileOne: Yup.string().required("mobileOne"),
    mobileTwo: Yup.string().required("mobileTwo"),
    telephone: Yup.string().required("telephone"),

    //DROPDOWN

    occupation: Yup.string().required("occupation"), //dropdown
    gender: Yup.string().required("gender"), //dropdown
    patCategory: Yup.string().required("patCategory"), //dropdown
    nationality: Yup.string().required("nationality"), //dropdown
    idProofType: Yup.string().required("idProofType"), //dropdown
    maritalStatus: Yup.string().required("maritalStatus"), //dropdown
    kinName: Yup.string().trim().required("kinName"), //dropdown
    religion: Yup.string().trim().required("religion"), //dropdown
    patientType: Yup.string().required("patientType"), //dropdown
    bloodGroup: Yup.string().required("bloodGroup"), //dropdown
    howYouHear: Yup.string().required("howYouHear"), //dropdown
    // regType: Yup.string().required("regType"), //dropdown
    visitedType: Yup.string().required("visitedType"), //dropdown
    referType: Yup.string().required("referType"), //dropdown
    contactVia: Yup.string().required("contactVia"), //dropdown
    cardType: Yup.string().required("cardType"), //dropdown
    bankName: Yup.string().required("bankName"), //dropdown

    //DATE

    issueDate: Yup.string().required("issueDate"), //date
    expireDate: Yup.string().required("expireDate"), //date
    regExp: Yup.string().required("regExp"), //date
    regDate: Yup.string().required("regDate"), //date

    //CHECKBOX

    umr: Yup.string().required("umr"),

    //PRESENT ADDRESS

    presentAddress: Yup.string().required("presentAddress"),
    presentArea: Yup.string().required("presentArea"),
    presentCity: Yup.string().required("presentCity"),
    presentZip: Yup.string().required("presentZip"),
    presentDistrict: Yup.string().required("presentDistrict"),
    presentCounty: Yup.string().required("presentCounty"),

    //PERMANENT ADDRESS

    permanentAddress: Yup.string().required("permanentAddress"),
    permanentArea: Yup.string().required("permanentArea"),
    permanentCity: Yup.string().required("permanentCity"),
    permanentState: Yup.string().required("permanentState"),
    permanentZip: Yup.string().required("permanentZip"),
    permanentDistrict: Yup.string().required("permanentDistrict"),
    permanentCounty: Yup.string().required("permanentCounty"),

    //PAYMENT

    patientGross: Yup.string().required("patientGross"),
    partyGross: Yup.string().required("partyGross"),
    patientTax: Yup.string().required("patientTax"),
    patientDue: Yup.string().required("patientDue"),
    partyDue: Yup.string().required("partyDue"),
    dueAuthAndPat: Yup.string().required("partyDue"),

    // enableanddisablestatus:Yup.string().required("status is required"),
  });

  const basicInforValidationSchema = Yup.object({
    regularVipVvip: Yup.string().required("Required"),
    patientType: Yup.string().required("patientType"), //dropdown
    title: Yup.string().trim().required("title"),
    firstName: Yup.string().required("firstName"),
    lastName: Yup.string(),
    middleName: Yup.string(),
    passport: Yup.string(),
    fathersName: Yup.string(),
    mothersName: Yup.string(),
    idProof: Yup.string(),
    doctor: Yup.string(),
    date: Yup.string().trim(),
    familyRef: Yup.string(),
    issuedAt: Yup.string(),
    regFee: Yup.string(),
    occupation: Yup.string(), //dropdown
    gender: Yup.string(), //dropdown
    patCategory: Yup.string(), //dropdown
    nationality: Yup.string(), //dropdown
    idProofType: Yup.string(), //dropdown
    maritalStatus: Yup.string(), //dropdown
    kinName: Yup.string().trim(), //dropdown
    religion: Yup.string().trim(), //dropdown
    bloodGroup: Yup.string(), //dropdown
    howYouHear: Yup.string(), //dropdown
    issueDate: Yup.string(), //date
    expireDate: Yup.string(), //date
    regExp: Yup.string(), //date
    regDate: Yup.string(), //date
    umr: Yup.string(),
  });
  const referalValidationSchema = Yup.object({
    rOne: Yup.object(),
    rTwo: Yup.object(),
    rThree: Yup.object(),
    rFour: Yup.object(),
  });
  const addressValidationSchema = Yup.object({
    //PRESENT ADDRESS Field
    presentAddress: Yup.string().required("presentAddress"),
    presentAddress2: Yup.string(),
    presentArea: Yup.string().required("presentArea"),
    presentCity: Yup.string(),
    presentZip: Yup.string().matches(
      /^\d{6}$/,
      "PIN code must be exactly 6 digits"
    ),
    presentDistrict: Yup.string(),
    presentCounty: Yup.string().required("presentCounty"),
    permanentAddress: Yup.string().required("permanentAddress"),
    permanentAddress2: Yup.string(),
    permanentArea: Yup.string().required("Area"),
    permanentCity: Yup.string(),
    permanentState: Yup.string(),
    permanentZip: Yup.string().matches(
      /^\d{6}$/,
      "PIN code must be exactly 6 digits"
    ),
    permanentDistrict: Yup.string(),
    permanentCounty: Yup.string().required("permanentCounty"),
  });

  const contactValidationSchema = Yup.object({
    mobileOne: Yup.string().required("mobileOne"),
    mobileTwo: Yup.string(),
    telephone: Yup.string(),
    nearestPs: Yup.string(),
    contactVia: Yup.string(), //dropdown
  });

  const paymentValidationSchema = Yup.object({
    receiptHashTag: Yup.string(),
    cashAmount: Yup.string(),
    cardHashTag: Yup.string(),
    authHash: Yup.string(),
    receiptDate: Yup.string().required("receiptDate"),
    cardExpDate: Yup.string().required("cardExpDate"),
    cardHolderName: Yup.string().required("cardHolderName"),
    cardType: Yup.string().required("cardType"), //dropdown
    bankName: Yup.string().required("bankName"), //dropdown
    dueAuthAndPat: Yup.string(),
    notes: Yup.string(),
    remarks: Yup.string(),
    cardAmount: Yup.string(),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initiaValues,
    validationSchema: () => {
      if (selectedTab === 1) {
        return basicInforValidationSchema;
      } else if (selectedTab === 2) {
        return referalValidationSchema;
      } else if (selectedTab === 3) {
        return addressValidationSchema;
      } else if (selectedTab === 4) {
        return contactValidationSchema;
      } else {
        return paymentValidationSchema;
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
      if (selectedTab < 5) {
        setselectedTab(selectedTab + 1);
        if(!(completedTab.includes(selectedTab+1))){
          setcompletedTab([...completedTab,selectedTab + 1])
        }
      } else {
        const postdata = {
          title: values.title,
          date: values.date,
          kinName: values.kinName,
          religion: values.religion,
          patientType: values.patientType,
          bloodGroup: values.bloodGroup,
          passport: values.passport,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          gender: values.gender,
          occupation: values.occupation,
          idProof: values.idProof,
          idProofType: values.idProofType,
          howYouHear: values.howYouHear,
          issueDate: values.issueDate,
          expireDate: values.expireDate,
          fathersName: values.fathersName,
          maritalStatus: values.maritalStatus,
          race: values.race,
          mothersName: values.mothersName,
          patCategory: values.patCategory,
          nationality: values.nationality,
          doctor: values.doctor,
          familyRef: values.familyRef,
          issuedAt: values.issuedAt,
          regularVipVvip: values.regularVipVvip,
          regType: values.regExp,
          regFee: values.regFee,
          regExp: values.regExp,
          umr: values.umr,
          regDate: values.regDate,
          visitedType: values.visitedType,
          remarks: values.remarks,
          presentAddress: values.presentAddress + values.presentAddress2,
          permanentAddress: values.permanentAddress + values.permanentAddress2,
          presentArea: values.presentArea,
          permanentArea: values.permanentArea,
          presentCity: values.presentCity,
          permanentCity: values.permanentCity,
          presentState: values.presentState,
          permanentState: values.permanentState,
          presentZip: values.presentZip,
          permanentZip: values.permanentZip,
          presentDistrict: values.presentZip,
          permanentDistrict: values.permanentDistrict,
          presentCounty: values.presentCounty,
          permanentCounty: values.permanentCounty,
          mobileOne: values.mobileTwo,
          mobileTwo: values.mobileTwo,
          telephone: values.telephone,
          email: values.email,
          contactVia: values.contactVia,
          nearestPs: values.nearestPs,
          patientGross: "100",
          partyGross: "200",
          patientTax: "10",
          patientDue: "0",
          partyDue: "0",
          dueAuthAndPat: values.dueAuthAndPat,
          receiptHashTag: values.receiptHashTag,
          cashAmount: values.cashAmount,
          cardType: values.cardType,
          cardHashTag: values.cardHashTag,
          cardAmount: values.cardAmount,
          authHash: values.authHash,
          bankName: values.bankName,
          receiptDate: values.receiptDate,
          cardExpDate: values.cardExpDate,
          cardHolderName: values.cardHolderName,
          rOne: values.rOne,
          rTwo: values.rTwo,
          rThree: values.rThree,
          rFour: values.rFour,
        };
        const { data } = await axios.post(
          `https://api.hospital.demo.pairalabs.com/api/v1/save-registration`,
          postdata
        );
        if (data?.status === "success") {
          alert("Registred Successfully");
          resetForm();
          setcompletedTab([1])
          setselectedTab(1);
        }
      }
    },
  });
  function handleCheckBox(value) {
    console.log("handleCheckBox", value);
    setFieldValue("regType", value);
  }

  const tabBar = [
    {
      name: "Basic",
      isCompleteCircle: true,
      isSelectedCircle: true,
      isSelectedBar: true,
    },
    {
      name: "Referral",
      isCompleteCircle: false,
      isSelectedCircle: true,
      isSelectedBar: false,
    },
    {
      name: "Address",
      isCompleteCircle: false,
      isSelectedCircle: false,
      isSelectedBar: false,
    },
    {
      name: "Contact",
      isCompleteCircle: false,
      isSelectedCircle: false,
      isSelectedBar: false,
    },
    {
      name: "Payment",
      isCompleteCircle: false,
      isSelectedCircle: false,
      isSelectedBar: true,
      notShowBar: true,
    },
  ];
  const handleCompleteTab=(v)=>{
    if(!(completedTab.includes(v))){
      setcompletedTab([...completedTab,v])
    }
  }
  return (
    <div className="md:hidden">
      {/* for tab-1 */}
      {selectedTab === 1 && (
        <Root
          setTab={setselectedTab}
          completedTab={completedTab}
          onSubmit={handleSubmit}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
          handleCompleteTab={handleCompleteTab}
          selectedTab={selectedTab}
        />
      )}
      {/* for tab 2 */}
      {selectedTab === 2 && (
        <Referral
        selectedTab={selectedTab}
          values={values}
          completedTab={completedTab}
          onSubmit={handleSubmit}
          setTab={setselectedTab}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
          handleCompleteTab={handleCompleteTab}
        />
      )}
      {/* for tab 3 */}
      {selectedTab === 3 && (
        <Address
          values={values}
          completedTab={completedTab}
          onSubmit={handleSubmit}
          setTab={setselectedTab}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
          handleCompleteTab={handleCompleteTab}
          selectedTab={selectedTab}
        />
      )}
      {/* for tab 4 */}
      {selectedTab === 4 && (
        <Contact
          values={values}
          completedTab={completedTab}
          onSubmit={handleSubmit}
          setTab={setselectedTab}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
          handleCompleteTab={handleCompleteTab}
          selectedTab={selectedTab}
        />
      )}
      {/* for tab 5 */}
      {selectedTab === 5 && (
        <Payment
          completedTab={completedTab}
          values={values}
          onSubmit={handleSubmit}
          setTab={setselectedTab}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
          handleCompleteTab={handleCompleteTab}
          selectedTab={selectedTab}
        />
      )}
    </div>
  );
};

export default MobileView;
