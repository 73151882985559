import React from "react";

const FormInput = ({
  value,
  maxLength,
  type,
  error,
  customClass,
  isBorderPadding,
  onBlur,
  name,
  placeholder,
  onChange,
  important,
  label,
}) => {
  return (
    <div className="flex items-center   " style={{width:"100%"}}>
      <label
        className={`block text-sm ${
          important ? "after:content-['*'] after:ml-0.5 after:text-red-500" : ""
        } text-gray-700 font-medium`}
        htmlFor="name"
      >
        {label}
      </label>
      <input
         autoComplete="new-one"
        type={type}
        maxLength={maxLength}
        style={error ?{border:"1px solid red",color:value&&"#000",opacity:value&&1}:{color:value&&"#000",opacity:value&&1}}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder || "Enter"}
        className={`   outline-none ${
          isBorderPadding ? "border-none " : "border-[1px]  border-gray-500"
        }  rounded-sm ${customClass}`}
      />
    </div>
  );
};

export default FormInput;
