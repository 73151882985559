import React, { useState } from "react";
import "./web.scss";
import {
  WebViewDateInput,
  WebViewInput,
  WebViewSelect,
} from "./WebViewComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  bankOptionsIndia,
  cardTypeOptions,
  citiesOfOdisha,
  countries,
  districtsOfOdisha,
  idProofOptions,
  nationalityOptions,
  occupationOptions,
  patientCategoryOptions,
  raceOptions,
  referBy,
  referralType,
  regTypeOptions,
  religionOptions,
  statesOfIndia,
  umrOptions,
  visitedTypeOptions,
} from "../../data/selectData";
import MenuIcon from "../../assets/icon/menu-fill@1x.svg";
import DashboardIcon from "../../assets/icon/dashboard-fill@1x.svg";
import PieChartIcon from "../../assets/icon/pie-chart-2-fill@1x.svg";
import HeartIcon from "../../assets/icon/heart-3-fill@1x.svg";
import MailIcon from "../../assets/icon/mail-fill@1x.svg";
import CameraIcon from "../../assets/icon/camera@1x.svg";
import SettingsIcon from "../../assets/icon/settings-5-fill@1x.svg";
import QuestionIcon from "../../assets/icon/question-fill@1x.svg";
import logo from "../../assets/images/logo-1.jpg"
// import { Camera } from 'react-feather';
// {/* <Camera color="white" size={48} /> */}

const DesltopView = ({}) => {
  const [selectedTab, setselectedTab] = useState(0);
  const initiaValues = {
    referalTab: 0,
    addressTab: 0,
    title: "mr",
    dob: "",
    date: "",
    kinName: "",
    religion: "",
    patientType: "dnd",
    bloodGroup: "",
    passport: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    occupation: "",
    idProof: "",
    idProofType: "",
    howYouHear: "",
    issueDate: "",
    expireDate: "",
    fathersName: "",
    maritalStatus: "",
    race: "",
    mothersName: "",
    patCategory: "",
    nationality: "indian",
    doctor: "",
    familyRef: "",
    issuedAt: "",
    regularVipVvip: "regular",
    regType: "dnd",
    regFee: "",
    regExp: "",
    umr: "",
    regDate: "",
    visitedType: "",
    referType: "",
    referBy: "",
    referSource: "",
    referTo: "",
    address: "",
    phone: "",
    remarks: "",
    presentAddress: "",
    presentAddress2: "",
    permanentAddress1: "",
    permanentAddress2: "",
    presentArea: "",
    permanentArea: "",
    presentCity: "",
    permanentCity: "",
    presentState: "",
    permanentState: "",
    presentZip: "",
    permanentZip: "",
    presentDistrict: "",
    permanentDistrict: "",
    presentCounty: "india",
    permanentCounty: "india",
    mobileOne: "",
    mobileTwo: "",
    telephone: "",
    email: "",
    contactVia: "",
    nearestPs: "",
    patientGross: "",
    partyGross: "",
    patientTax: "",
    patientDue: "",
    partyDue: "",
    dueAuthAndPat: "",
    receiptHashTag: "",
    cashAmount: "",
    cardType: "",
    cardHashTag: null,

    authHash: null,
    bankName: null,
    receiptDate: null,
    cardExpDate: null,
    cardHolderName: null,
    cardAmount: null,
    rOne: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    rTwo: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    rThree: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    rFour: {
      referal_type: "",
      referred_by: "",
      source: "",
      referred_to: "",
      address: "",
      phone: "",
      remark: "",
    },
    notes: "",
  };
  const basicInforValidationSchema = Yup.object({
    regularVipVvip: Yup.string().required("Required"),
    patientType: Yup.string().required("patientType"), //dropdown
    title: Yup.string().trim().required("title"),
    firstName: Yup.string().required("firstName"),
    lastName: Yup.string(),
    middleName: Yup.string(),
    passport: Yup.string(),
    fathersName: Yup.string(),
    mothersName: Yup.string(),
    idProof: Yup.string(),
    doctor: Yup.string(),
    dob: Yup.string().trim(),
    familyRef: Yup.string(),
    issuedAt: Yup.string(),
    regFee: Yup.string(),
    occupation: Yup.string(), //dropdown
    gender: Yup.string(), //dropdown
    patCategory: Yup.string(), //dropdown
    nationality: Yup.string(), //dropdown
    idProofType: Yup.string(), //dropdown
    maritalStatus: Yup.string(), //dropdown
    kinName: Yup.string().trim(), //dropdown
    religion: Yup.string().trim(), //dropdown
    bloodGroup: Yup.string(), //dropdown
    howYouHear: Yup.string(), //dropdown
    issueDate: Yup.string(), //date
    expireDate: Yup.string(), //date
    regExp: Yup.string(), //date
    regDate: Yup.string(), //date
    umr: Yup.string(),
  });
  const referalValidationSchema = Yup.object({
    rOne: Yup.object(),
    rTwo: Yup.object(),
    rThree: Yup.object(),
    rFour: Yup.object(),
  });
  const addressValidationSchema = Yup.object({
    //PRESENT ADDRESS Field
    presentAddress: Yup.string().required("presentAddress"),
    presentAddress2: Yup.string(),
    presentArea: Yup.string().required("presentArea"),
    presentCity: Yup.string(),
    presentZip: Yup.string().matches(
      /^\d{6}$/,
      "PIN code must be exactly 6 digits"
    ),
    presentDistrict: Yup.string(),
    presentCounty: Yup.string().required("presentCounty"),
    permanentAddress1: Yup.string().required("permanentAddress"),
    permanentAddress2: Yup.string(),
    permanentArea: Yup.string().required("Area"),
    permanentCity: Yup.string(),
    permanentState: Yup.string(),
    permanentZip: Yup.string().matches(
      /^\d{6}$/,
      "PIN code must be exactly 6 digits"
    ),
    permanentDistrict: Yup.string(),
    permanentCounty: Yup.string().required("permanentCounty"),
  });
  const contactValidationSchema = Yup.object({
    mobileOne: Yup.string().required("mobileOne"),
    mobileTwo: Yup.string(),
    telephone: Yup.string(),
    nearestPs: Yup.string(),
    contactVia: Yup.string(), //dropdown
  });
  const validationSchema = Yup.object({
    receiptHashTag: Yup.string(),
    cashAmount: Yup.string(),
    cardHashTag: Yup.string(),
    authHash: Yup.string(),
    receiptDate: Yup.string().required("receiptDate"),
    cardExpDate: Yup.string().required("cardExpDate"),
    cardHolderName: Yup.string().required("cardHolderName"),
    cardType: Yup.string().required("cardType"), //dropdown
    bankName: Yup.string().required("bankName"), //dropdown
    dueAuthAndPat: Yup.string(),
    notes: Yup.string(),
    remarks: Yup.string(),
    cardAmount: Yup.string(),
  });
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initiaValues,
    validationSchema: () => {
      if (selectedTab === 0) {
        return basicInforValidationSchema;
      } else if (selectedTab === 1) {
        return referalValidationSchema;
      } else if (selectedTab === 2) {
        return addressValidationSchema;
      } else if (selectedTab === 3) {
        return contactValidationSchema;
      } else {
        return validationSchema;
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values, action) => {
      if (selectedTab < 4) {
        setselectedTab(selectedTab + 1);
      } else {
        //Api Call
        const postdata = {
          title: values.title,
          date: values.dob,
          kinName: values.kinName,
          religion: values.religion,
          patientType: values.patientType,
          bloodGroup: values.bloodGroup,
          passport: values.passport,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          gender: values.gender,
          occupation: values.occupation,
          idProof: values.idProof,
          idProofType: values.idProofType,
          howYouHear: values.howYouHear,
          issueDate: values.issueDate,
          expireDate: values.expireDate,
          fathersName: values.fathersName,
          maritalStatus: values.maritalStatus,
          race: values.race,
          mothersName: values.mothersName,
          patCategory: values.patCategory,
          nationality: values.nationality,
          doctor: values.doctor,
          familyRef: values.familyRef,
          issuedAt: values.issuedAt,
          regularVipVvip: values.regularVipVvip,
          regType: values.regExp,
          regFee: values.regFee,
          regExp: values.regExp,
          umr: values.umr,
          regDate: values.regDate,
          visitedType: values.visitedType,
          remarks: values.remarks,
          presentAddress: values.presentAddress + values.presentAddress2,
          permanentAddress: values.permanentAddress1 + values.permanentAddress2,
          presentArea: values.presentArea,
          permanentArea: values.permanentArea,
          presentCity: values.presentCity,
          permanentCity: values.permanentCity,
          presentState: values.presentState,
          permanentState: values.permanentState,
          presentZip: values.presentZip,
          permanentZip: values.permanentZip,
          presentDistrict: values.presentZip,
          permanentDistrict: values.permanentDistrict,
          presentCounty: values.presentCounty,
          permanentCounty: values.permanentCounty,
          mobileOne: values.mobileTwo,
          mobileTwo: values.mobileTwo,
          telephone: values.telephone,
          email: values.email,
          contactVia: values.contactVia,
          nearestPs: values.nearestPs,
          patientGross: "100",
          partyGross: "200",
          patientTax: "10",
          patientDue: "0",
          partyDue: "0",
          dueAuthAndPat: values.dueAuthAndPat,
          receiptHashTag: values.receiptHashTag,
          cashAmount: values.cashAmount,
          cardType: values.cardType,
          cardHashTag: values.cardHashTag,
          cardAmount: values.cardAmount,
          authHash: values.authHash,
          bankName: values.bankName,
          receiptDate: values.receiptDate,
          cardExpDate: values.cardExpDate,
          cardHolderName: values.cardHolderName,
          rOne: values.rOne,
          rTwo: values.rTwo,
          rThree: values.rThree,
          rFour: values.rFour,
        };
        const { data } = await axios.post(
          `https://api.hospital.demo.pairalabs.com/api/v1/save-registration`,
          postdata
        );
        if (data?.status === "success") {
          alert("Registred Successfully");
          resetForm();
          setselectedTab(0);
        }
      }
    },
  });

  React.useEffect(() => {
    // Initialize the code
    return () => {};
  }, []);

  const patientType = [
    {
      label: "Senior Citizen",
      value: "senior citizen",
    },
    {
      label: "DND",
      value: "dnd",
    },
    {
      label: "MLC",
      value: "mlc",
    },
    {
      label: "Health Card",
      value: "health card",
    },
    {
      label: "New Born",
      value: "new born",
    },
    {
      label: "Renewal",
      value: "renewal",
    },
  ];
  const handleReferalChange = (element, type) => {
    if (values.referalTab === 0) {
      setFieldValue("rOne", {
        ...values.rOne,
        [type]: element.target.value,
      });
    } else if (values.referalTab === 1) {
      setFieldValue("rTwo", {
        ...values.rTwo,
        [type]: element.target.value,
      });
    } else if (values.referalTab === 2) {
      setFieldValue("rThree", {
        ...values.rThree,
        [type]: element.target.value,
      });
    } else if (values.referalTab === 3) {
      setFieldValue("rFour", {
        ...values.rFour,
        [type]: element.target.value,
      });
    }
  };
  const getReferalTabValue = (field) => {
    if (values.referalTab === 0) {
      return values.rOne[field];
    } else if (values.referalTab === 1) {
      return values.rTwo[field];
    } else if (values.referalTab === 2) {
      return values.rThree[field];
    } else if (values.referalTab === 3) {
      return values.rFour[field];
    }
  };

  return (
    <div className={"desktop-4"}>
      <div className={"frame-3453"}>
        <div className={"form"}>
          {/* Basic Information */}
          {selectedTab === 0 && (
            <div className={"frame-3472"}>
              <div className={"card"}>
                <div className={"frame-3463"}>
                  <div className={"basic-information"}>Basic Information</div>
                </div>
                <div className={"frame-3447"}>
                  <div className={"patient-type"}>Patient Type</div>
                  <div className={"tags"}>
                    {patientType?.map((patient) =>
                      values.patientType !== patient.value ? (
                        <div
                          className={"tag cursor-pointer"}
                          onClick={() =>
                            setFieldValue("patientType", patient.value)
                          }
                        >
                          {patient?.label}
                          {/* <div className={"senior-citizen"}>
                          </div> */}
                        </div>
                      ) : (
                        <div className={"tag selected cursor-pointer"}>
                          {patient?.label}
                          {/* <div className={"dnd"}>{patient?.label}</div> */}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className={"frame-3456"}>
                  <div className={"frame-3455"}>
                    <div className={"frame-3446"}>
                      <div className={"patient-details"}>Patient Details</div>
                      <div className={"flex flex-col gap-4 w-full"}>
                        <div className={"flex gap-4"}>
                          <WebViewSelect
                            label={"Title"}
                            options={[
                              { label: "Select", value: "" },
                              { label: "Mr", value: "mr" },
                              { label: "Mrs", value: "mrs" },
                            ]}
                            name={"title"}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.title && touched.title}
                          />
                          <WebViewInput
                            label={"First Name"}
                            isImportant={true}
                            placeholder="Enter first name"
                            name={"firstName"}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.firstName && touched.firstName}
                          />
                          <WebViewInput
                            label={"Middle Name"}
                            isImportant={false}
                            placeholder="Enter middle name"
                            name={"middleName"}
                            value={values.middleName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.middleName && touched.middleName}
                          />
                          <WebViewInput
                            label={"Last Name"}
                            isImportant={false}
                            placeholder="Enter last name"
                            name={"lastName"}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.lastName && touched.lastName}
                          />
                        </div>
                        <div className={"flex gap-4"}>
                          <WebViewSelect
                            label={"Gender"}
                            options={[
                              { label: "Select", value: "" },
                              { label: "Male", value: "male" },
                              { label: "Female", value: "female" },
                              { label: "Others", value: "others" },
                            ]}
                            name={"gender"}
                            value={values.gender}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.gender && touched.gender}
                          />
                          <WebViewDateInput
                            label={"Birthday"}
                            isImportant={false}
                            name={"dob"}
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.dob && touched.dob}
                          />
                          {/* <div className={'formitem-6'}>
                                <div className={'select-6'}>
                                    Patient Type
                                </div>
                                <div className={'frame-3477'}>
                                    <div className={'select-7'}>
                                        Select
                                    </div>
                                    <svg id="133:03760/29:14590" className={'arrow-down-s-fill-3'}></svg>
                                </div>
                            </div> */}
                          <WebViewSelect
                            label={"Pat Category"}
                            options={[
                              { label: "Select", value: "" },
                              ...patientCategoryOptions,
                            ]}
                            name={"patCategory"}
                            value={values.patCategory}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.patCategory && touched.patCategory}
                          />
                          <div className="w-full"></div>
                        </div>
                      </div>
                    </div>
                    {/* More information */}
                    <div className={"frame-3444"}>
                      <div className={"more-information"}>More Information</div>
                      <div className={"flex gap-4"}>
                        <WebViewSelect
                          // parentClassName={"formitem-8"}
                          // labelClassName={"select-10"}
                          label={"Blood Group"}
                          className={"frame-3479 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            { label: "O+", value: "o+" },
                            { label: "O-", value: "o-" },
                            { label: "A+", value: "a+" },
                            { label: "B+", value: "b+" },
                            { label: "AB+", value: "ab+" },
                            { label: "AB-", value: "ab-" },
                          ]}
                          name={"bloodGroup"}
                          value={values.bloodGroup}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.bloodGroup && touched.bloodGroup}
                        />
                        <WebViewSelect
                          // parentClassName={"formitem-9"}
                          // labelClassName={"select-12"}
                          label={"Maritual Status"}
                          className={"frame-3480 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            { label: "Married", value: "married" },
                            { label: "Unmarried", value: "unmarried" },
                          ]}
                          name={"maritalStatus"}
                          value={values.maritalStatus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.maritalStatus && touched.maritalStatus}
                        />
                        <WebViewSelect
                          // parentClassName={"formitem-10"}
                          // labelClassName={"select-14"}
                          label={"Occupation"}
                          className={"frame-3481 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            ...occupationOptions,
                          ]}
                          name={"occupation"}
                          value={values.occupation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.occupation && touched.occupation}
                        />
                        <WebViewSelect
                          // parentClassName={"formitem-11"}
                          // labelClassName={"select-16"}
                          label={"Nationality"}
                          className={"frame-3482 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            ...nationalityOptions,
                          ]}
                          name={"nationality"}
                          value={values.nationality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.nationality && touched.nationality}
                        />
                        <WebViewSelect
                          // parentClassName={"formitem-12"}
                          // labelClassName={"select-18"}
                          label={"Race"}
                          className={"frame-3483 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            ...raceOptions,
                          ]}
                          name={"race"}
                          value={values.race}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.race && touched.race}
                        />
                        <WebViewSelect
                          // parentClassName={"formitem-13"}
                          // labelClassName={"select-20"}
                          label={"Religion"}
                          className={"frame-3484 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            ...religionOptions,
                          ]}
                          name={"religion"}
                          value={values.religion}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.religion && touched.religion}
                        />
                      </div>
                    </div>
                    {/* Family Details */}
                    <div className={"frame-3445"}>
                      <div className={"family-details"}>Family Details</div>
                      <div className={"flex gap-4 w-full"}>
                        <WebViewInput
                          // className={"frame-3485 outline-none"}
                          label={"Father Name"}
                          isImportant={false}
                          placeholder="Enter father name"
                          name={"fathersName"}
                          value={values.fathersName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.fathersName && touched.fathersName}
                        />
                        <WebViewInput
                          // className={"frame-3486 outline-none"}
                          label={"Mother Name"}
                          isImportant={false}
                          placeholder="Enter mother name"
                          name={"mothersName"}
                          value={values.mothersName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.mothersName && touched.mothersName}
                        />
                        <WebViewInput
                          // className={"frame-3487 outline-none"}
                          label={"Kin Name"}
                          isImportant={false}
                          placeholder="Enter kin name"
                          name={"kinName"}
                          value={values.kinName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.kinName && touched.kinName}
                        />
                      </div>
                    </div>
                    {/* Verification */}
                    <div className={"frame-3443"}>
                      <div className={"verification"}>Verification</div>
                      <div className={"flex flex-col gap-4 w-full"}>
                        <WebViewSelect
                          label={"Id Proof"}
                          className={"frame-3488 outline-none"}
                          options={[
                            { label: "Select", value: "" },
                            ...idProofOptions,
                          ]}
                          name={"idProof"}
                          value={values.idProof}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.idProof && touched.idProof}
                        />
                        <div className={"flex gap-4 w-full"}>
                          <WebViewInput
                            // className={"frame-3490 outline-none"}
                            label={"Passport"}
                            isImportant={false}
                            placeholder="Enter passport No."
                            name={"passport"}
                            value={values.passport}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.passport && touched.passport}
                          />
                          <WebViewDateInput
                            // className={"form-input outline-none"}
                            label={"Issue Date"}
                            isImportant={false}
                            name={"issueDate"}
                            value={values.issueDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.issueDate && touched.issueDate}
                          />
                          <WebViewDateInput
                            // className={"form-input outline-none"}
                            label={"Expire Date"}
                            isImportant={false}
                            name={"expireDate"}
                            value={values.expireDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.expireDate && touched.expireDate}
                          />
                          <WebViewDateInput
                            // className={"form-input outline-none"}
                            label={"Issue At"}
                            isImportant={false}
                            name={"issuedAt"}
                            value={values.issuedAt}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.issuedAt && touched.issuedAt}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"frame-3454"}>
                <div className={"frame-3440"}>
                  <div className={"tabs"}>
                    <div
                      className={`cursor-pointer ${
                        values.regularVipVvip === "regular"
                          ? "itemchecked text-white"
                          : "itemnormal"
                      }`}
                      onClick={() => setFieldValue("regularVipVvip", "regular")}
                    >
                      <div className={"regular"}>Regular</div>
                    </div>
                    <div
                      className={`cursor-pointer ${
                        values.regularVipVvip === "vip"
                          ? "itemchecked text-white"
                          : "itemnormal"
                      }`}
                      onClick={() => setFieldValue("regularVipVvip", "vip")}
                    >
                      <div className={"vip"}>VIP</div>
                    </div>
                    <div
                      className={`cursor-pointer ${
                        values.regularVipVvip === "vvip"
                          ? "itemchecked text-white"
                          : "itemnormal"
                      }`}
                      onClick={() => setFieldValue("regularVipVvip", "vvip")}
                    >
                      <div className={"vvip"}>VVIP</div>
                    </div>
                  </div>
                  <div className={"flex gap-4 w-full"}>
                    <div className="flex flex-col gap-4 w-full">
                      <WebViewSelect
                        label={"Reg Type"}
                        options={[
                          {
                            label: "Select",
                            value: "",
                          },
                          ...regTypeOptions,
                        ]}
                        name={"regType"}
                        value={values.regType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.regType && touched.regType}
                      />
                      <WebViewSelect
                        label={"UMR"}
                        options={[
                          { label: "Select", value: "" },
                          ...umrOptions,
                        ]}
                        name={"umr"}
                        value={values.umr}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.umr && touched.umr}
                      />
                      <WebViewInput
                        label={"Reg  Fee"}
                        isImportant={false}
                        placeholder="Enter Reg fee."
                        name={"regFee"}
                        value={values.regFee}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.regFee && touched.regFee}
                      />
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <WebViewDateInput
                        label={"Reg Dt"}
                        isImportant={false}
                        name={"regDate"}
                        value={values.regDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.regDate && touched.regDate}
                      />
                      <WebViewDateInput
                        label={"Reg Exp Dt"}
                        isImportant={false}
                        name={"regExp"}
                        value={values.regExp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.regExp && touched.regExp}
                      />
                      <WebViewSelect
                        label={"Visit Type"}
                        options={[
                          { label: "Select", value: "" },
                          ...visitedTypeOptions,
                        ]}
                        name={"visitedType"}
                        value={values.visitedType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.visitedType && touched.visitedType}
                      />
                    </div>
                  </div>
                </div>
                <div className={"frame-3450"}>
                  <WebViewInput
                    className={"input-1 outline-none"}
                    // parentClassName={"input"}
                    label={"Docter"}
                    isImportant={false}
                    // labelClassName={"doctor"}
                    placeholder="Enter docter name."
                    name={"doctor"}
                    value={values.doctor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.doctor && touched.doctor}
                  />
                  <WebViewInput
                    className={"input-3 outline-none"}
                    // parentClassName={"input-2"}
                    label={"Family Reff."}
                    isImportant={false}
                    // labelClassName={"family-reff"}
                    name={"familyRef"}
                    value={values.familyRef}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.familyRef && touched.familyRef}
                  />
                </div>
                <div className={"frame-3451"}>
                  <WebViewSelect
                    // parentClassName={"input-4"}
                    // labelClassName={"how-did-you-hear-about-us"}
                    label={"How did you hear about us?"}
                    className={"input-5 outline-none"}
                    options={[
                      { label: "Select", value: "" },
                      { label: "Word Of Mouth", value: "word of mouth" },
                      { label: "From Internet", value: "from internet" },
                      { label: "Others", value: "others" },
                    ]}
                    name={"howYouHear"}
                    value={values.howYouHear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.howYouHear && touched.howYouHear}
                  />
                </div>
              </div>
            </div>
          )}
          {/* Referal */}
          {selectedTab === 1 && (
            <div className={"frame-3458"}>
              <div className={"frame-3501"}>
                <div className={"referral"}>Referral</div>
              </div>
              <div className={"tabs-1 w-full"}>
                <div
                  className={`cursor-pointer ${
                    values.referalTab === 0
                      ? "itemchecked-1 relative"
                      : "itemnormal-2"
                  }`}
                  onClick={() => setFieldValue("referalTab", 0)}
                >
                  <div className={"r1"}>R1</div>
                  {values.referalTab === 0 && <div className={"checked"}></div>}
                </div>
                <div
                  className={`cursor-pointer ${
                    values.referalTab === 1
                      ? "itemchecked-1 relative"
                      : "itemnormal-2"
                  }`}
                  onClick={() => setFieldValue("referalTab", 1)}
                >
                  <div className={"r1"}>R2</div>
                  {values.referalTab === 1 && <div className={"checked"}></div>}
                </div>
                <div
                  className={`cursor-pointer ${
                    values.referalTab === 2
                      ? "itemchecked-1 relative"
                      : "itemnormal-2"
                  }`}
                  onClick={() => setFieldValue("referalTab", 2)}
                >
                  <div className={"r1"}>R3</div>
                  {values.referalTab === 2 && <div className={"checked"}></div>}
                </div>
                <div
                  className={`cursor-pointer ${
                    values.referalTab === 3
                      ? "itemchecked-1 relative"
                      : "itemnormal-2"
                  }`}
                  onClick={() => setFieldValue("referalTab", 3)}
                >
                  <div className={"r1"}>R4</div>
                  {values.referalTab === 3 && <div className={"checked"}></div>}
                </div>
                <svg id="133:04082" className={"frame-3441"}></svg>
              </div>
              <div className={"form-5"}>
                <div className={"flex flex-col gap-4 w-full"}>
                  <div className={"flex gap-4 w-full"}>
                    <WebViewSelect
                      label={"Refer Type"}
                      options={[
                        { label: "Select", value: "" },
                        ...referralType
                      ]}
                      name={"referType"}
                      onChange={(e) => handleReferalChange(e, "referal_type")}
                      onBlur={handleBlur}
                      value={getReferalTabValue("referal_type")}
                    />
                    <WebViewSelect
                      label={"Refered By"}
                      options={[
                        { label: "Select", value: "" },
                        ...referBy
                      ]}
                      name={"referBy"}
                      value={getReferalTabValue("referred_by")}
                      onChange={(e) => handleReferalChange(e, "referred_by")}
                      onBlur={handleBlur}
                    />
                    <WebViewInput
                      label={"Source Name"}
                      isImportant={false}
                      placeholder="Enter source name"
                      name={"referSource"}
                      value={getReferalTabValue("source")}
                      onChange={(e) => handleReferalChange(e, "source")}
                      onBlur={handleBlur}
                    />
                    <WebViewInput
                      label={"Refer To"}
                      isImportant={false}
                      placeholder="Refer To"
                      name={"referTo"}
                      value={getReferalTabValue("referred_to")}
                      onChange={(e) => handleReferalChange(e, "referred_to")}
                      onBlur={handleBlur}
                    />
                    <WebViewInput
                      label={"Phone"}
                      isImportant={false}
                      placeholder="phone"
                      name={"phone"}
                      onChange={(e) => handleReferalChange(e, "phone")}
                      onBlur={handleBlur}
                      value={getReferalTabValue("phone")}
                    />
                    <WebViewInput
                      label={"Address"}
                      isImportant={false}
                      placeholder="Address"
                      name={"address"}
                      value={getReferalTabValue("address")}
                      onChange={(e) => handleReferalChange(e, "address")}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={"formitem"}>
                    <div className={"formitem-label"}>Remark</div>
                    <textarea
                      className={"input-6 outline-none"}
                      placeholder="Type something..."
                      onChange={(e) => handleReferalChange(e, "remark")}
                      value={getReferalTabValue("remark")}
                    ></textarea>
                    {/* <div className={'text-0-200'}>
                                        0/200
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Address */}
          {selectedTab === 2 && (
            <div className={"frame-3459"}>
              <div className={"frame-3508"}>
                <div className={"address"}>Address</div>
              </div>
              <div className={"tabs-2"}>
                <div
                  className={` cursor-pointer ${
                    values.addressTab === 0
                      ? "itemchecked relative"
                      : "itemnormal"
                  }`}
                  onClick={() => setFieldValue("addressTab", 0)}
                >
                  <div className={"present"}>Present</div>
                  {values.addressTab === 0 && (
                    <div className={"checked-1"}></div>
                  )}
                </div>
                <div
                  className={` cursor-pointer ${
                    values.addressTab === 1
                      ? "itemchecked relative"
                      : "itemnormal"
                  }`}
                  onClick={() => setFieldValue("addressTab", 1)}
                >
                  <div className={"permanent"}>Permanent</div>
                  {values.addressTab === 1 && (
                    <div className={"checked-1"}></div>
                  )}
                </div>
              </div>
              <div className={"frame-3448"}>
                {values.addressTab === 0 ? (
                  <div className={"flex flex-col gap-4"}>
                    <WebViewInput
                      label={"Address 1"}
                      isImportant={true}
                      placeholder="presentAddress"
                      name={"presentAddress"}
                      value={values.presentAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.presentAddress && touched.presentAddress}
                    />
                    <WebViewInput
                      label={"Address 2"}
                      isImportant={false}
                      placeholder="Address2"
                      name={"presentAddress2"}
                      value={values.presentAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.presentAddress2 && touched.presentAddress2}
                    />
                    <div className="flex gap-4 w-full">
                      <WebViewInput
                        label={"Area"}
                        isImportant={true}
                        placeholder="presentArea"
                        name={"presentArea"}
                        value={values.presentArea}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.presentArea && touched.presentArea}
                      />
                      <WebViewInput
                        label={"pin/zip"}
                        isImportant={false}
                        placeholder="presentZip"
                        name={"presentZip"}
                        value={values.presentZip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.presentZip && touched.presentZip}
                      />
                      <WebViewSelect
                        label={"City"}
                        options={[
                          { value: "", label: "Select" },
                          ...citiesOfOdisha,
                        ]}
                        name={"presentCity"}
                        value={values.presentCity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.presentCity && touched.presentCity}
                      />
                      <WebViewSelect
                        label={"District"}
                        options={[
                          { value: "", label: "Select" },
                          ...districtsOfOdisha,
                        ]}
                        name={"presentDistrict"}
                        value={values.presentDistrict}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.presentDistrict && touched.presentDistrict
                        }
                      />
                      <WebViewSelect
                        label={"State"}
                        options={[
                          { value: "", label: "Select" },
                          ...statesOfIndia,
                        ]}
                        name={"presentState"}
                        value={values.presentState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.presentState && touched.presentState}
                      />
                      <WebViewSelect
                        label={"Country"}
                        options={[{ value: "", label: "Select" }, ...countries]}
                        name={"presentCounty"}
                        value={values.presentCounty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.presentCounty && touched.presentCounty}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={"flex flex-col gap-4"}>
                    <WebViewInput
                      label={"Address 1"}
                      isImportant={true}
                      placeholder="permanentAddress1"
                      name={"permanentAddress1"}
                      value={values.permanentAddress1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.permanentAddress1 && touched.permanentAddress1
                      }
                    />
                    <WebViewInput
                      label={"Address 2"}
                      isImportant={false}
                      placeholder="Address2"
                      name={"permanentAddress2"}
                      value={values.permanentAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.permanentAddress2 && touched.permanentAddress2
                      }
                    />
                    <div className="flex gap-4 w-full">
                      <WebViewInput
                        label={"Area"}
                        isImportant={true}
                        placeholder="Area"
                        name={"permanentArea"}
                        value={values.permanentArea}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.permanentArea && touched.permanentArea}
                      />
                      <WebViewInput
                        label={"pin/zip"}
                        isImportant={false}
                        placeholder="Zip/Pin"
                        name={"permanentZip"}
                        value={values.permanentZip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.permanentZip && touched.permanentZip}
                      />
                      <WebViewSelect
                        label={"City"}
                        options={[
                          { value: "", label: "Select" },
                          ...citiesOfOdisha,
                        ]}
                        name={"permanentCity"}
                        value={values.permanentCity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.permanentCity && touched.permanentCity}
                      />
                      <WebViewSelect
                        label={"District"}
                        options={[
                          { value: "", label: "Select" },
                          ...districtsOfOdisha,
                        ]}
                        name={"permanentDistrict"}
                        value={values.permanentDistrict}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.permanentDistrict && touched.permanentDistrict
                        }
                      />
                      <WebViewSelect
                        label={"State"}
                        options={[
                          { value: "", label: "Select" },
                          ...statesOfIndia,
                        ]}
                        name={"permanentState"}
                        value={values.permanentState}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.permanentState && touched.permanentState}
                      />
                      <WebViewSelect
                        label={"Country"}
                        options={[{ value: "", label: "Select" }, ...countries]}
                        name={"permanentCounty"}
                        value={values.permanentCounty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.permanentCounty && touched.permanentCounty
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Contact Details */}
          {selectedTab === 3 && (
            <div className={"frame-3460"}>
              <div className={"tabs-3"}>
                <div className={"contact-details"}>Contact Details</div>
              </div>
              <div className={"frame-3452"}>
                <div className={"flex gap-4 w-full"}>
                  <WebViewInput
                    label={"Mobile1"}
                    isImportant={true}
                    placeholder="Enter"
                    name={"mobileOne"}
                    value={values.mobileOne}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.mobileOne && touched.mobileOne}
                  />
                  <WebViewInput
                    label={"Mobile2"}
                    isImportant={false}
                    placeholder="Enter"
                    name={"mobileTwo"}
                    value={values.mobileTwo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.mobileTwo && touched.mobileTwo}
                  />
                  <WebViewInput
                    label={"Telephone"}
                    isImportant={false}
                    placeholder="Enter"
                    name={"telephone"}
                    value={values.telephone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.telephone && touched.telephone}
                  />
                  <WebViewSelect
                    label={"Contact via"}
                    options={[
                      { label: "Select", value: "" },
                      { label: "Phone", value: "phone" },
                      { label: "Email", value: "email" },
                    ]}
                    name={"contactVia"}
                    value={values.contactVia}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.contactVia && touched.contactVia}
                  />
                  <WebViewInput
                    className={"frame-3522 outline-none"}
                    // parentClassName={"formitem-48"}
                    label={"Nearest Ps"}
                    isImportant={false}
                    // labelClassName={"text-24"}
                    placeholder="Enter"
                    name={"nearestPs"}
                    value={values.nearestPs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nearestPs && touched.nearestPs}
                  />
                </div>
              </div>
            </div>
          )}
          {/* Payment */}
          {selectedTab === 4 && (
            <div className={"frame-3461"}>
              <div className={"frame-3523"}>
                <div className={"payment"}>Payment</div>
              </div>
              <div className={"tabs-4"}>
                <div className={"itemchecked-3"}>
                  <div className={"quick"}>Quick</div>
                  <div className={"checked-2"}></div>
                </div>
              </div>
              <div className={"flex flex-col gap-6 p-6 w-full form-9"}>
                <table className="table w-full">
                  <thead className="bg-[#F7F7F8]">
                    <tr>
                      <th className="px-4 py-4 w-24">Bill Info</th>
                      <th className="px-4 py-4">Patient</th>
                      <th className="px-4 py-4">Party</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="px-4 py-4">Gross</th>
                      <th className="px-4 py-4">180</th>
                      <th className="px-4 py-4">45</th>
                    </tr>
                    <tr className="bg-[#F7F7F8]">
                      <th className="px-4 py-4">Tax</th>
                      <th className="px-4 py-4">200</th>
                      <th className="px-4 py-4">40</th>
                    </tr>
                    <tr>
                      <th className="px-4 py-4">Due</th>
                      <th className="px-4 py-4">165</th>
                      <th className="px-4 py-4">40</th>
                    </tr>
                  </tbody>
                </table>
                <div className={"w-full"}>
                  <div className={"formitem"}>
                    <div className={"formitem-label"}>
                      <span>Due Auth. Pat </span>
                      <span>*</span>
                    </div>
                    <input
                      className={"input-8"}
                      placeholder="Enter"
                      name={"dueAuthAndPat"}
                      value={values.dueAuthAndPat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.dueAuthAndPat && touched.dueAuthAndPat}
                    />
                  </div>
                </div>
                <div className={"frame-3525"}>
                  <div className={"receipt-info"}>Receipt Info</div>
                  <div className={"flex gap-4 w-full"}>
                    <WebViewInput
                      label={"Receipt"}
                      isImportant={false}
                      placeholder="Enter"
                      name={"receiptHashTag"}
                      value={values.receiptHashTag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.receiptHashTag && touched.receiptHashTag}
                    />
                    <WebViewDateInput
                      label={"Receipt Dt"}
                      isImportant={false}
                      placeholder="Enter"
                      name={"receiptDate"}
                      value={values.receiptDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.receiptDate && touched.receiptDate}
                    />
                    <WebViewInput
                      label={"Cash Amt"}
                      isImportant={false}
                      placeholder="Enter"
                      name={"cashAmount"}
                      value={values.cashAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.cashAmount && touched.cashAmount}
                    />
                  </div>
                </div>
                <div className={"frame-3529 w-full"}>
                  <div className={"card-details"}>Card Details</div>
                  <div className={"flex flex-col gap-4 w-full"}>
                    <WebViewSelect
                      label={"Card Type"}
                      options={[
                        { label: "Select", value: "" },
                        ...cardTypeOptions,
                      ]}
                      name={"cardType"}
                      value={values.cardType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.cardType && touched.cardType}
                    />
                    <div className="flex gap-4 w-full">
                      <WebViewInput
                        label={"Card Number"}
                        isImportant={false}
                        placeholder="Enter"
                        name={"cardHashTag"}
                        value={values.cardHashTag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.cardHashTag && touched.cardHashTag}
                      />
                      <WebViewSelect
                        label={"Bank Name"}
                        options={[
                          { label: "Select", value: "" },
                          ...bankOptionsIndia,
                        ]}
                        name={"bankName"}
                        value={values.bankName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.bankName && touched.bankName}
                      />
                      <WebViewDateInput
                        label={"Exp. Dt"}
                        isImportant={false}
                        placeholder="Enter"
                        name={"cardExpDate"}
                        value={values.cardExpDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.cardExpDate && touched.cardExpDate}
                      />
                    </div>
                    <div className="flex gap-4 w-full">
                      <WebViewInput
                        label={"Name on Card"}
                        isImportant={false}
                        placeholder="Enter"
                        name={"cardHolderName"}
                        value={values.cardHolderName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.cardHolderName && touched.cardHolderName}
                      />
                      <WebViewInput
                        label={"Amount"}
                        isImportant={false}
                        placeholder="Enter"
                        name={"cardAmount"}
                        value={values.cardAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.cardAmount && touched.cardAmount}
                      />
                      <WebViewInput
                        label={"Auth"}
                        isImportant={false}
                        placeholder="Enter"
                        name={"authHash"}
                        value={values.authHash}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.authHash && touched.authHash}
                      />
                    </div>
                    <div className={"formitem"}>
                      <div className={"formitem-label"}>Remarks</div>
                      <textarea
                        className={"form-input"}
                        value={values.remarks}
                        name="remarks"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className={"details"}>
                  <div className={"listitem"}>
                    <div className={"change-in-inr"}>Change in INR</div>
                    <div className={"price"}>
                      <div className={"text-38"}>0</div>
                    </div>
                  </div>
                  <div className={"listitem-1"}>
                    <div className={"change-amount-in-words"}>
                      Change amount in words
                    </div>
                    <div className={"price-1"}>
                      <div className={"text-39"}>0</div>
                    </div>
                  </div>
                  <div className={"listitem-2"}>
                    <div className={"total-receipt-amount-in-words"}>
                      Total Receipt amount in words
                    </div>
                    <div className={"price-2"}>
                      <div className={"visa"}>Visa</div>
                    </div>
                  </div>
                  <div className={"listitem-3"}>
                    <div className={"cash-amount-in-words"}>
                      Cash amount in words
                    </div>
                    <div className={"price-3"}>
                      <div className={"text-"}>₹</div>
                      <div className={"text-40"}>39</div>
                    </div>
                  </div>
                  <div className={"listitem-4"}>
                    <div className={"card-amount-in-words"}>
                      Card amount in words
                    </div>
                    <div className={"price-4"}>
                      <div className={"text--1"}>₹</div>
                      <div className={"text-41"}>10</div>
                    </div>
                  </div>
                  <div className={"listitem-5"}>
                    <div className={"total-1"}>Total</div>
                    <div className={"price-5"}>
                      <div className={"text--2"}>₹</div>
                      <div className={"text-49"}>49</div>
                    </div>
                  </div>
                </div>
                <div className={"input-10"}>
                  <div className={"notes"}>Notes</div>
                  <textarea
                    className={"input-11 outline-none"}
                    placeholder=" Please provide your personal details below:"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={"frame-3537"}>
        <div className={"frame-3538"}>
          {selectedTab !== 0 && (
            <button
              className={"frame-3539 cursor-pointer"}
              onClick={() => setselectedTab(selectedTab - 1)}
              disabled={selectedTab === 0 ? "disable" : ""}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                data-name="Layer 1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.328 10 8.203 3.125l0.829 0.828L3.57 9.414H18.672v1.172H3.57l5.463 5.463L8.203 16.875Z" />
              </svg>
              <div className={"back"}>Back</div>
            </button>
          )}
          <div className={"frame-3540"}>
            <div className={"registration"}>Registration</div>
          </div>
        </div>
        <div className={"steps"}>
          <div
            className={"step"}
            style={
              selectedTab === 0
                ? { border: "1px solid #3578ff" }
                : { border: "1px solid #F0F4F6" }
            }
          >
            <div
              className={"number"}
              style={
                selectedTab === 0
                  ? {
                      border: "1px solid #3578ff",
                      background: "#3578ff",
                      color: "black",
                    }
                  : {
                      border: "1px solid #F0F4F6",
                      background: "#F0F4F6",
                      color: "black",
                    }
              }
            >
              <div className={"text-42"} style={{ color: selectedTab === 0?"#fff":"inherit" }}>
                1
              </div>
            </div>
            <div className={"frame-3541"}>
              <div className={"basic"}>Basic</div>
            </div>
          </div>
          <svg id="152:08275" className={"divider"}></svg>
          <div
            className={"step-1"}
            style={
              selectedTab === 1
                ? { border: "1px solid #3578ff" }
                : { border: "1px solid #F0F4F6" }
            }
          >
            <div
              className={"number-1"}
              style={
                selectedTab === 1
                  ? { border: "1px solid #3578ff", background: "#3578ff" }
                  : { border: "1px solid #F0F4F6", background: "#F0F4F6" }
              }
            >
              <div className={"text-43"} style={{ color: selectedTab === 1?"#fff":"inherit" }}>2</div>
            </div>
            <div className={"frame-3542"}>
              <div className={"referral-1"}>Referral</div>
            </div>
          </div>
          <svg id="152:08283" className={"divider-1"}></svg>
          <div
            className={"step-2"}
            style={
              selectedTab === 2
                ? { border: "1px solid #3578ff" }
                : { border: "1px solid #F0F4F6" }
            }
          >
            <div
              className={"number-2"}
              style={
                selectedTab === 2
                  ? { border: "1px solid #3578ff", background: "#3578ff" }
                  : { border: "1px solid #F0F4F6", background: "#F0F4F6" }
              }
            >
              <div className={"text-44"} style={{ color: selectedTab === 2?"#fff":"inherit" }}>3</div>
            </div>
            <div className={"frame-3543"}>
              <div className={"address-1"}>Address</div>
            </div>
          </div>
          <svg id="152:08291" className={"divider-2"}></svg>
          <div
            className={"step-3"}
            style={
              selectedTab === 3
                ? { border: "1px solid #3578ff" }
                : { border: "1px solid #F0F4F6" }
            }
          >
            <div
              className={"number-3"}
              style={
                selectedTab === 3
                  ? { border: "1px solid #3578ff", background: "#3578ff" }
                  : { border: "1px solid #F0F4F6", background: "#F0F4F6" }
              }
            >
              <div className={"text-45"} style={{ color: selectedTab === 3?"#fff":"inherit" }}>4</div>
            </div>
            <div className={"frame-3544"}>
              <div className={"contact"}>Contact</div>
            </div>
          </div>
          <svg id="152:08299" className={"divider-3"}></svg>
          <div
            className={"step-4"}
            style={
              selectedTab === 4
                ? { border: "1px solid #3578ff" }
                : { border: "1px solid #F0F4F6" }
            }
          >
            <div
              className={"number-4"}
              style={
                selectedTab === 4
                  ? { border: "1px solid #3578ff", background: "#3578ff" }
                  : { border: "1px solid #F0F4F6", background: "#F0F4F6" }
              }
            >
              <div className={"text-46"} style={{ color: selectedTab === 4?"#fff":"inherit" }}>5</div>
            </div>
            <div className={"frame-3545"}>
              <div className={"payment-1"}>Payment</div>
            </div>
          </div>
        </div>
        <div className={"frame-3546"}>
          <div className={"group-395"}>
            <svg
              fill="#fff"
              width="14px"
              height="14px"
              viewBox="0 0 0.6 0.6"
              id="cross"
              data-name="Flat Color"
              xmlns="http://www.w3.org/2000/svg"
              class="icon flat-color"
            >
              <path
                id="primary"
                d="m0.335 0.3 0.158 -0.157a0.025 0.025 0 1 0 -0.035 -0.035L0.3 0.265l-0.157 -0.158a0.025 0.025 0 0 0 -0.035 0.035l0.158 0.157 -0.158 0.157a0.025 0.025 0 0 0 0 0.035 0.025 0.025 0 0 0 0.035 0l0.157 -0.158 0.157 0.158a0.025 0.025 0 0 0 0.035 0 0.025 0.025 0 0 0 0 -0.035Z"
              />
            </svg>
            <div className={"cancel"}>Cancel</div>
          </div>
          <div className={"group-394 relative cursor-pointer"} onClick={handleSubmit}>
          <svg width="14" height="14" viewBox="0 0 0.375 0.375" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m.025.175.113.113L.35.075" stroke-width=".045" stroke="#fff" stroke-linecap="square"/></svg>
            <div className={"save "} >
              {selectedTab === 4 ? "Submit" : "Next"}
            </div>
          </div>
        </div>
      </div>
      <div className={"frame-3547"}>
        <div className={"navbar"}>
          <img
            src={logo}
            className={"logo-1"}
          />
          <div className={"menu-fill"}>
            <img src={MenuIcon} />
          </div>
          <div className={"dashboard-fill"}>
            <img src={DashboardIcon} />
          </div>
          <div className={"pie-chart-2-fill"}>
            <img src={PieChartIcon} />
          </div>
          <div className={"heart-3-fill"}>
            <img src={HeartIcon} />
          </div>
          <div className={"mail-fill"}>
            <img src={MailIcon} />
          </div>
        </div>
        <div className={"navbar-1"}>
          <div className={"nav"}>
            <div className={"location"}>
              <div className={"your-ip-address"}>Your IP address</div>
              <div className={"text-102038161"}>10.20.38.161</div>
            </div>
          </div>
          <div className={"search"}>
            <div className={"placeholder"}>
              <div className={"camera-fill"}>
                <img src={CameraIcon} />
              </div>
              <div className={"divider-4"}></div>
              <div className={"search-for-document"}>Search for document</div>
            </div>
            <div className={"button-1"}>
              <div className={"search-1"}>Search</div>
            </div>
          </div>
        </div>
        <div className={"navbar-2"}>
          <div className={"question-fill"}>
            <img src={SettingsIcon} />
          </div>
          <div className={"settings-5-fill"}>
            <img src={QuestionIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DesltopView;