import './App.css';
import { useFormik } from 'formik';
import * as Yup from "yup"

import MobileView from './components/common/MobileView';
import DesltopView from './components/common/web';
import { useEffect } from 'react';

function App() {


  useEffect(() => {
    const preventPinchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    let lastTouchEnd = 0;

    const preventDoubleTapZoom = (event) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    };

    document.addEventListener('touchmove', preventPinchZoom, { passive: false });
    document.addEventListener('touchend', preventDoubleTapZoom, false);

    return () => {
      document.removeEventListener('touchmove', preventPinchZoom);
      document.removeEventListener('touchend', preventDoubleTapZoom);
    };
  }, []);






  const initiaValues={
      "title": "mr",
      "dob": "mr",
      "date": "",
      "kinName": "",
      "religion": "",
      "patientType": "",
      "bloodGroup": "",
      "passport": "",
      "firstName": "",
      "middleName": "",
      "lastName": "",
      "gender": "",
      "occupation": "",
      "idProof": "",
      "idProofType": "",
      "howYouHear": "",
      "issueDate": "",
      "expireDate": "",
      "fathersName": "",
      "maritalStatus": "",
      "race": "",
      "mothersName": "",
      "patCategory": "",
      "nationality": "",
      "doctor": "",
      "familyRef": "",
      "issuedAt": "",
      "regularVipVvip": "",
      "regType": "dnd",
      "regFee": "",
      "regExp": "",
      "umr": "",
      "regDate": "",
      "visitedType": "",
      "referType": "",
      "referBy": "",
      "referSource": "",
      "referTo": "",
      "address": "",
      "phone": "",
      "remarks": "",
      "presentAddress": "",
      "permanentAddress": "",
      "presentArea": "",
      "permanentArea": "",
      "presentCity": "",
      "permanentCity": "",
      "presentState": "",
      "permanentState": "",
      "presentZip": "",
      "permanentZip": "",
      "presentDistrict": "",
      "permanentDistrict": "",
      "presentCounty": "",
      "permanentCounty": "",
      "mobileOne": "",
      "mobileTwo": "",
      "telephone": "",
      "email": "",
      "contactVia": "",
      "nearestPs": "",
      "patientGross": "",
      "partyGross": "",
      "patientTax": "",
      "patientDue": "",
      "partyDue": "",
      "dueAuthAndPat": "",
      "receiptHashTag": "",
      "cashAmount": "",
      "cardType": "",
      "cardHashTag": null,
      
      "authHash": null,
      "bankName": null,
      "receiptDate": null,
      "cardExpDate": null,
      "cardHolderName": null
 }

 const validationSchema = Yup.object({
  // RADIO BUTTON
  regularVipVvip:Yup.string().required("required"),
  //INPUT FIELD
  title: Yup.string().trim().required("title"),
  date: Yup.string().trim().required("date"),
  passport: Yup.string().required("passport"),
  firstName: Yup.string().required("firstName"),
  lastName: Yup.string().required("lastName"),
  fathersName: Yup.string().required("fathersName"),
  mothersName: Yup.string().required("mothersName"),
  idProof: Yup.string().required("idProof"),
  doctor: Yup.string().required("doctor"),
  familyRef: Yup.string().required("familyRef"),
  issuedAt: Yup.string().required("issuedAt"),
  regFee: Yup.string().required("regFee"),
  referBy: Yup.string().required("referBy"),
  referSource: Yup.string().required("referBy"),
  referTo: Yup.string().required("referTo"),
  email: Yup.string().required("email"),
  nearestPs: Yup.string().required("nearestPs"),


  receiptHashTag: Yup.string().required("receiptHashTag"),
  cashAmount: Yup.string().required("cashAmount"),
  cardHashTag: Yup.string().required("cardHashTag"),
  authHash: Yup.string().required("authHash"),
  receiptDate: Yup.string().required("receiptDate"),
  cardExpDate: Yup.string().required("cardExpDate"),
  cardHolderName: Yup.string().required("cardHolderName"),

  // NUMBER

  mobileOne: Yup.string().required("mobileOne"),
  mobileTwo: Yup.string().required("mobileTwo"),
  telephone: Yup.string().required("telephone"),


  //DROPDOWN

  occupation: Yup.string().required("occupation"), //dropdown
  gender: Yup.string().required("gender"), //dropdown
  patCategory: Yup.string().required("patCategory"),  //dropdown
  nationality: Yup.string().required("nationality"),  //dropdown
  idProofType: Yup.string().required("idProofType"),  //dropdown
  maritalStatus: Yup.string().required("maritalStatus"),  //dropdown
  kinName: Yup.string().trim().required("kinName"), //dropdown
  religion: Yup.string().trim().required("religion"), //dropdown
  patientType: Yup.string().required("patientType"), //dropdown
  bloodGroup: Yup.string().required("bloodGroup"), //dropdown
  howYouHear: Yup.string().required("howYouHear"), //dropdown
  // regType: Yup.string().required("regType"), //dropdown
  visitedType: Yup.string().required("visitedType"), //dropdown
  referType: Yup.string().required("referType"), //dropdown
  contactVia: Yup.string().required("contactVia"), //dropdown
  cardType: Yup.string().required("cardType"), //dropdown
  bankName: Yup.string().required("bankName"), //dropdown
  
  //DATE

  issueDate: Yup.string().required("issueDate"),  //date
  expireDate: Yup.string().required("expireDate"),  //date
  regExp: Yup.string().required("regExp"),  //date
  regDate: Yup.string().required("regDate"),  //date
  
  //CHECKBOX

  umr: Yup.string().required("umr"),  
   


  //PRESENT ADDRESS
   
  presentAddress: Yup.string().required("presentAddress"),
  presentArea: Yup.string().required("presentArea"),
  presentCity: Yup.string().required("presentCity"),
  presentZip: Yup.string().required("presentZip"),
  presentDistrict: Yup.string().required("presentDistrict"),
  presentCounty: Yup.string().required("presentCounty"),
  
  //PERMANENT ADDRESS
  
  permanentAddress: Yup.string().required("permanentAddress"),
  permanentArea: Yup.string().required("permanentArea"),
  permanentCity: Yup.string().required("permanentCity"),
  permanentState: Yup.string().required("permanentState"),
  permanentZip: Yup.string().required("permanentZip"),
  permanentDistrict: Yup.string().required("permanentDistrict"),
  permanentCounty: Yup.string().required("permanentCounty"),
  
  //PAYMENT
  
  patientGross: Yup.string().required("patientGross"),
  partyGross: Yup.string().required("partyGross"),
  patientTax: Yup.string().required("patientTax"),
  patientDue: Yup.string().required("patientDue"),
  partyDue: Yup.string().required("partyDue"),
  dueAuthAndPat: Yup.string().required("partyDue"),


    // enableanddisablestatus:Yup.string().required("status is required"),
});
const {
        
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  resetForm,
  setFieldValue,
} = useFormik({
  initialValues:initiaValues,
  validationSchema: validationSchema,
  enableReinitialize:true,
  validateOnChange: true,
  validateOnBlur: false,
  onSubmit: async (values, action) => {
   
  },
});
console.log("values",values);
function handleCheckBox(value){
  setFieldValue("regType",value)
}

const titles = [
  {tag:"mr",name:"Mr"},
  {tag:"mrs",name:"Mrs"},
  {tag:"smt",name:"Smt"},
]
  return (
    <div>
      <div className='max-md:hidden'>

      <DesltopView/>
      </div>
      <MobileView/>

    </div>
);
};

  

export default App;

const ReferralInfo = () => {
  return (
    <div className="mt-4">
      <h2 className="text-lg font-semibold mb-2">Referral Info</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block">Referral Type</label>
          <select className="border p-2 rounded w-full">
            {/* Options */}
          </select>
        </div>
        
        {/* Other referral fields */}
        
      </div>
    </div>
  );
};

const AddressInfo = () => {
  return (
    <div className="mt-4">
      <h2 className="text-lg font-semibold mb-2">Address</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block">Present Address</label>
          <input type="text" placeholder="Address1" className="border p-2 rounded w-full mb-2" />
          <input type="text" placeholder="Address2" className="border p-2 rounded w-full mb-2" />
          <input type="text" placeholder="City" className="border p-2 rounded w-full mb-2" />
          <input type="text" placeholder="State" className="border p-2 rounded w-full mb-2" />
          <input type="text" placeholder="Pin/Zip" className="border p-2 rounded w-full mb-2" />
          <input type="text" placeholder="Country" className="border p-2 rounded w-full mb-2" />
        </div>
        
        {/* Permanent Address or other fields */}
        
      </div>
    </div>
  );
};


const PaymentInfo = () => {
  return (
    <div className="mt-4">
      <h2 className="text-lg font-semibold mb-2">Payment Info</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block">Bill Info</label>
          <div className="flex space-x-2">
            <input type="text" placeholder="Gross" className="border p-2 rounded w-full" />
            <input type="text" placeholder="Tax" className="border p-2 rounded w-full" />
            <input type="text" placeholder="Due" className="border p-2 rounded w-full" />
          </div>
        </div>
        
        {/* Other payment fields */}
        
      </div>
    </div>
  );
};

