import React from "react";

const TextArea = ({
  value,
  maxLength,
  type,
  error,
  customClass,
  isBorderPadding,
  onBlur,
  name,
  placeholder,
  onChange,
  important,
  label,
}) => {
  return (
    <div className="flex items-center gap-1  ">
      <label
        className={` hidden text-sm ${
          important ? "after:content-['*'] after:ml-0.5 after:text-red-500" : ""
        } text-gray-700 font-medium`}
        htmlFor="name"
      >
        {label}
      </label>
      <textarea
        // type={type}
        maxLength={199}
        style={
          error
            ? { border: "1px solid red", color: "#000", opacity: value&&1,width:"270px" }
            : { color: "#000", opacity: value&&1,width:"232px" }
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder || "Enter"}
        className={`   outline-none ${
          isBorderPadding ? "border-none " : "border-[1px]  border-gray-500"
        }  rounded-sm w-full ${customClass}`}
      ></textarea>
    </div>
  );
};

export default TextArea;
